import { useState, useEffect, useRef } from 'react'
import HarvestApiService from '../service/HarvestApi.jsx'

const useExportStatus = (jwt, pollingInterval, errorCallback) => {
  const [statusResults, setStatusResults] = useState({})

  const poll = useRef(null)

  const getBatchFileRequests = () => {
    HarvestApiService.getBatchFileRequests(jwt, (results) => {
      setStatusResults(results.data)
    },
    (error) => {
      onError(error)
    })
  }

  // call api on mount to fetch results right away
  useEffect(getBatchFileRequests, [])

  const onError = (error) => {
    clearInterval(poll)
    errorCallback(error)
  }

  // polling set up
  useEffect(() => {
    const startPolling = () => {
      const pollTimeout = setInterval(getBatchFileRequests, pollingInterval)
      poll.current = pollTimeout
    }

    const stopPolling = () => {
      if (poll.current) {
        clearInterval(poll.current)
        poll.current = null
      }
    }

    // start the poll on mount
    startPolling()

    // cleanup on unmount: stop polling
    return () => {
      stopPolling()
    }
  }, [statusResults]) // eslint-disable-line react-hooks/exhaustive-deps
  // statusResults is the only dependency we care about in this useEffect;
  // no need to pass in additional objects in the dependency array

  return [statusResults]
}

export default useExportStatus

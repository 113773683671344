import React, { useState, useEffect } from 'react'

import '../../../scss/form/input-text-area.scss'

const InputTextArea = (props) => {
  const initialState = {
    value: '',
    rows: 1,
    onFocus: false
  }
  const [inputState, setInputState] = useState(initialState)

  useEffect(() => {
    setInputState({
      ...inputState,
      value: props.inputValue.join('\n'),
      rows: props.inputValue.length > 0 ? props.inputValue.length : 1
    })
  }, [props.inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeInput = (event) => {
    const { value } = event.target
    const rowCount = value ? value.split('\n').length : 1
    setInputState({ ...inputState, value: value, rows: rowCount })
    const ids = value !== '' ? value.split('\n') : []
    props.setValue(ids)
  }

  return (
    <div disabled={props.disabled} className='input-text-area' tabIndex={props.tabIndex}>
      <label htmlFor={props.inputId} className={inputState.value || inputState.onFocus ? '' : 'placeholder-text'}>
        {props.label} <span className='example-text' style={{ fontSize: '10px' }}>e.g. {props.example}</span>
      </label>
      <textarea
        id={props.inputId}
        type='search'
        tabIndex={props.disabled ? '-1' : '1'}
        onChange={handleChangeInput}
        onFocus={() => setInputState({ ...inputState, onFocus: true })}
        onBlur={() => setInputState({ ...inputState, onFocus: false })}
        value={inputState.value}
        rows={inputState.rows}
      />
    </div>
  )
}

export default InputTextArea

import React, { useState } from 'react'
import NumberFormat from 'react-number-format'

import '../../../scss/form/input-text-field.scss'

const InputDateField = ({ inputId, inputValue, setValue, label, example, autoComplete }) => {
  const [onFocus, toggleFocus] = useState(false)

  function handleInputChange ({ formattedValue }) {
    setValue(formattedValue)
  }

  return (
    <div className='input-text-field' tabIndex='-1'>
      <label htmlFor={inputId} className={inputValue || onFocus ? '' : 'placeholder-text'}>
        {label} <span className='example-text'>e.g. {example}</span>
      </label>
      <NumberFormat
        id={inputId}
        type='search'
        format='####-##-##'
        value={inputValue}
        onFocus={() => toggleFocus(true)}
        onBlur={() => toggleFocus(false)}
        onValueChange={handleInputChange}
        autoComplete={autoComplete}
      />
    </div>
  )
}

export default InputDateField

import React, { Component } from 'react'
import HarvestContext from '../context/HarvestContext'
import ScopusForm from './form/ScopusForm'
import PureForm from './form/PureForm'
import OrcidForm from './form/OrcidForm'
import PubMedForm from './form/PubMedForm'
import RemoveAccents from 'remove-accents'
import HarvestApiService from '../service/HarvestApi.jsx'
import {
  trackNewPageEvent,
  trackSearchCriteria,
  LoadTracker
} from '../../analytics'

import '../../scss/harvest-main.scss'
import '../../scss/harvest-search-input.scss'
import * as DateFns from 'date-fns'
import { adjustFrameHeight } from '../../utils'
import ModalExport from './ModalExport'

const SOURCE_SCOPUS = 'scopus'
const SOURCE_PURE = 'pure'
const SOURCE_ORCID = 'orcid'
const SOURCE_PUBMED = 'pubmed'

// have to use a LoadTracker object outside of context
// b/c context is not available in constructor;
// will only be ref & called once
const loadTracker = new LoadTracker()

class SearchStart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      emptyInputs: true,
      initialSearch: true,
      dataSource: '',
      searchParams: {},
      isFormValid: false,
      openInputUI: true,
      showAffiliationModal: false,
      hideAffiliationTooltip: true,
      showContentTypeModal: false,
      showPubMedDetails: false,
      showPureDetails: false,
      showScopusDetails: false,
      publicationsData: {},
      showExportModal: false,
      exportModalMsg: ''
    }
    this.handleChangeSearchParams = this.handleChangeSearchParams.bind(this)
    this.isFormClear = this.isFormClear.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
    this.handleContinueSearch = this.handleContinueSearch.bind(this)
    this.handleContentTypeSearch = this.handleContentTypeSearch.bind(this)
    this.handleDataSourceChange = this.handleDataSourceChange.bind(this)
    this.checkValidForm = this.checkValidForm.bind(this)

    loadTracker.startTracking()
  }

  componentDidMount () {
    loadTracker.stopTracking()
    trackNewPageEvent(
      `harvest:${this.state.dataSource}:search`,
      loadTracker.loadTime
    )
  }

  componentDidUpdate (prevProps, prevState) {
    // condition for returning from search results
    if (
      this.context.openInputUI &&
      !prevState.openInputUI &&
      !this.state.openInputUI
    ) {
      this.context.loadTracker.stopTracking()
      this.setState(
        { openInputUI: true },
        trackNewPageEvent('harvest:search', this.context.loadTracker.loadTime)
      )

      adjustFrameHeight()
    }

    if (!this.context.openInputUI && prevState.openInputUI) {
      this.setState({ openInputUI: false })
    }

    if (prevState.dataSource !== this.state.dataSource) {
      adjustFrameHeight()
    }
  }

  handleChangeSearchParams (params) {
    this.setState(
      {
        searchParams: {
          ...this.state.searchParams,
          ...params
        }
      },
      () => {
        this.setState({ emptyInputs: this.isFormClear() })
      }
    )
  }

  async handleChangeAuthorAffiliation (value) {
    this.setState(
      {
        authorAffiliation: value,
        hideAffiliationTooltip: true,
        selectedAffiliations: [] // clear selections
      },
      () => {
        this.setState({ emptyInputs: this.isFormClear() })
      }
    )
  }

  handleClearSearch () {
    Object.keys(this.state.searchParams).forEach((key) => {
      // clears all string based form inputs fields
      // being a string.
      if (typeof this.state.searchParams[key] === 'string') {
        this.setState({ searchParams: { [key]: '' } })
      }

      // clears all array types in state
      if (Array.isArray(this.state.searchParams[key])) {
        this.setState({ searchParams: { [key]: [] } })
      }
    })
    // default sources
    this.setState({ emptyInputs: true })
  }

  handleModifySearch () {
    if (this.context.openInputUI) {
      // call the modified search
      this.handleSubmitSearch()
    } else {
      this.context.loadTracker.startTracking()
      this.context.actions.modifySearch(true)
      // SHOW-387: hide message if new affiliation query
      this.setState({
        hideAffiliationTooltip: false
      })
    }
  }

  handleSubmitSearch () {
    const {
      authorFirst,
      authorLast,
      authorId,
      orcidId,
      authorAffiliation,
      organizationalUnitID,
      contentFilters,
      selectedContentTypes,
      pubYearFrom,
      pubYearTo
    } = this.state.searchParams

    if (
      this.state.dataSource === SOURCE_SCOPUS &&
      authorAffiliation &&
      !authorFirst &&
      !authorLast
    ) {
      // SHOW-377: show affiliation modal if no author fields are complete
      this.authorAffiliationSearch(this.state.dataSource, authorAffiliation)
      return null
    }

    const first = this.normalizeString(authorFirst)
    const last = this.normalizeString(authorLast)
    const filters = contentFilters || []

    const [createDateRangeFrom, createDateRangeTo] = (function (params) {
      return [
        params.createDateFrom ? DateFns.formatISO(DateFns.parseISO(params.createDateFrom)) : '',
        params.createDateTo ? DateFns.formatISO(DateFns.parseISO(params.createDateTo)) : ''
      ]
    })(this.state.searchParams)

    const [pubDateRangeFrom, pubDateRangeTo] = (function (params) {
      return [
        params.pubDateFrom ? DateFns.formatISO(DateFns.parseISO(params.pubDateFrom)) : '',
        params.pubDateTo ? DateFns.formatISO(DateFns.parseISO(params.pubDateTo)) : ''
      ]
    })(this.state.searchParams)

    this.context.loadTracker.startTracking()

    // make call to parent function that intern calls API service
    // with the params: sources as Array, firstName(initial), lastName
    // send all of first name so the harvest search call can sort out who gets
    // just the first initial
    const searchParams = {
      dataSource: this.state.dataSource,
      siteContext: this.context.siteContext,
      firstName: first,
      lastName: last,
      authorId: authorId,
      orcidId: orcidId,
      authorAffiliation: authorAffiliation,
      pubYearFrom: pubYearFrom,
      pubYearTo: pubYearTo,
      includeUnmappedMetadata: false,
      includeSherpaRomeo: false,
      organizationalUnitID: organizationalUnitID,
      contentFilters:
        filters.includes('contentType') && selectedContentTypes.length > 0
          ? filters
          : filters.filter((c) => c !== 'contentType'),
      createDateFrom: createDateRangeFrom,
      createDateTo: createDateRangeTo,
      contentTypes: filters.includes('contentType')
        ? selectedContentTypes
        : null,
      pubDateFrom: pubDateRangeFrom,
      pubDateTo: pubDateRangeTo
    }
    // save last attempted search values
    // searchParams is an object of the last requestedParams (HarvestUI)
    // can be used to test if the search inputs have changed since last request
    this.setState({
      initialSearch: false,
      pubYearFrom: pubYearFrom,
      pubYearTo: pubYearTo
    })
    // scroll to top of page
    this.props.scrollToTop()
    return this.context.actions.authorHarvestSearch(searchParams)
  }

  handleContinueSearch () {
    this.context.loadTracker.startTracking()
    // close modal
    this.setState({
      showAffiliationModal: false
    })

    const [pubYearFrom, pubYearTo] = (function (state) {
      if (!(state.pubYearFrom || state.pubYearTo)) {
        return [state.pubYearFrom, state.pubYearTo]
      } else {
        return [
          state.pubYearFrom || 1,
          state.pubYearTo || new Date().getFullYear() + 1
        ]
      }
    })(this.state.searchParams)
    // build list of selected affils for SearchStatusUI header
    const selectedAffiliationsForDisplay = []
    this.state.searchParams.affiliationsList.data.forEach((affiliation) => {
      const { id, attributes } = affiliation
      if (this.state.searchParams.selectedAffiliations.includes(id)) {
        selectedAffiliationsForDisplay.push([id, attributes.Name])
      }
    })
    const searchParams = {
      dataSource: this.state.dataSource,
      affiliationID: this.state.searchParams.selectedAffiliations,
      siteContext: this.context.siteContext,
      selectedAffiliations: selectedAffiliationsForDisplay,
      pubYearFrom: pubYearFrom,
      pubYearTo: pubYearTo,
      includeSherpaRomeo: false,
      includeUnmappedMetadata: false
    }

    this.setState({
      initialSearch: false
    })
    return this.context.actions.authorHarvestSearch(searchParams)
  }

  authorAffiliationSearch (dataSource, searchParams) {
    this.context.loadTracker.startTracking()
    HarvestApiService.getAffiliationsSearch(
      dataSource,
      this.context.siteContext,
      searchParams,
      this.context.jwt,
      (results) => {
        // track affiliation search criteria
        trackSearchCriteria(
          `affiliations?dataSource=${dataSource}&query=${escape(searchParams)}`,
          { dataSource: dataSource, authorAffiliation: searchParams }
        )

        this.setState({
          searchParams: {
            ...this.state.searchParams,
            affiliationsList: results.data
          },
          showAffiliationModal: true
        })
      },
      (error) => {
        const title = error.response?.data.errors
          ? error.response.data.errors[0].title
          : 'Internal Server Error'
        const detail = error.response?.data.errors
          ? error.response.data.errors[0].detail
          : 'If this problem persists, contact your Consulting Services representative.'
        this.context.actions.openErrorModal(title, detail)
      }
    )
  }

  handleContentTypeSearch () {
    this.context.loadTracker.startTracking()
    HarvestApiService.getContentTypes(
      this.state.dataSource,
      this.context.siteContext,
      this.context.jwt,
      (results) => {
        this.setState({
          searchParams: {
            ...this.state.searchParams,
            contentTypes: results.data.data
          },
          showContentTypeModal: true
        })
      },
      (error) => {
        const title = error.response?.data.errors
          ? error.response.data.errors[0].title
          : 'Internal Server Error'
        const detail = error.response?.data.errors
          ? error.response.data.errors[0].detail
          : 'If this problem persists, contact your Consulting Services representative.'
        this.context.actions.openErrorModal(title, detail)
      }
    )
  }

  handleDataSourceChange (event) {
    this.context.loadTracker.startTracking()
    const { name } = event.target
    this.setState({ dataSource: name }, this.handleClearSearch())

    this.context.loadTracker.stopTracking()
    trackNewPageEvent(
      `harvest:${name}:search`,
      this.context.loadTracker.loadTime
    )
  }

  handleShowExportModal () {
    this.context.loadTracker.startTracking()

    HarvestApiService.getPublications(
      this.context.siteContext,
      this.context.jwt,
      (results) => {
        this.setState({
          publicationsData: results,
          exportModalMsg: {
            title: 'Export options',
            detail: 'Select an export format by Publication',
            confirmClick: () => {
              this.handleClearSearch()
            }
          },
          showExportModal: true
        })
      },
      (error) => {
        if (error.response) {
          const title = error.response.data.errors[0].title
          const detail = error.response.data.errors[0].detail
          this.context.actions.openErrorModal(title, detail)
        } else {
          const title = 'Internal Server Error'
          const detail =
            'If this problem persists, contact your Consulting Services representative.'
          this.context.actions.openErrorModal(title, detail)
        }
      }
    )
  }

  normalizeString (str) {
    if (!str) return ''
    return RemoveAccents(removePunctuation(str))
    function removePunctuation (str) {
      // remove special characters completely
      str = str.replace(/[/@#!$%^&*{}=\\_`~()]/g, '')
      // replace with a space
      // periods, dashes, commas, semi-colons, colons
      str = str.replace(/[.,;:-]/g, ' ')
      // clean up spaces cause by user and replacement
      str = str.replace(/ +(?= )/g, '').trim()
      return str
    }
  }

  isFormClear () {
    const somethingInForm = Object.keys(this.state.searchParams).find((key) => {
      if (Array.isArray(this.state.searchParams[key])) {
        return this.state.searchParams[key].length > 0
      }
      return (
        typeof this.state.searchParams[key] === 'string' &&
        this.state.searchParams[key] !== ''
      )
    })
    if (somethingInForm) {
      return false
    }
    return true
  }

  checkValidForm (valid) {
    this.setState({
      isFormValid: valid
    })
  }

  render () {
    const { authorFirst, authorLast, authorAffiliation } = this.state
    const affiliationOnly = authorAffiliation && !(authorFirst || authorLast)
    const affiliationToolTip =
      affiliationOnly &&
      !this.initialSearch &&
      !this.state.hideAffiliationTooltip
    const multiOrcidSearch = this.state.searchParams.orcidIds?.length > 0
    const pureEnabled = this.context.user?.pureKeyPresent === 1
    const scopusEnabled = this.context.user?.scopusTokenPresent === 1
    // this page gets rendered twice, and we don't
    // have the user context yet on the first pass, so don't try to init
    // dataSource until we have user context
    if (this.state.dataSource === '' && this.context.user) {
      if (this.context.user.scopusTokenPresent === 1) {
        this.setState({ dataSource: SOURCE_SCOPUS })
      } else if (this.context.user.pureKeyPresent === 1) {
        this.setState({ dataSource: SOURCE_PURE })
      } else {
        this.setState({ dataSource: SOURCE_ORCID })
      }
    }

    return (
      <div id='search-input' tabIndex='-1'>
        <div
          className={`search-input-body ${
            !this.context.requestLoading && this.context.openInputUI
              ? ''
              : 'collapsed'
          }`}
        >
          <header className='search-header'>
            <h1 className='harvestor-title'>Harvest Search</h1>
            <div className='subhead-small'>
              Select harvesting source and one or more criteria
            </div>
          </header>

          <div className='row'>
            {/* SOURCES */}
            <div className='col-lg-9'>
              <div className='sources'>
                <div className='section-title'>Source</div>
                <div
                  className='form-check form-check-inline'
                  disabled={!scopusEnabled}
                >
                  <input
                    className='form-check-input mr-2'
                    type='radio'
                    name={SOURCE_SCOPUS}
                    checked={this.state.dataSource === SOURCE_SCOPUS}
                    onChange={this.handleDataSourceChange}
                    data-testid={`radio-${SOURCE_SCOPUS}`}
                  />
                  <label className='form-check-label mr-3'>Scopus</label>
                </div>
                {!scopusEnabled && (
                  <span
                    style={{ left: '5.3em', position: 'absolute' }}
                    onMouseEnter={() => {
                      this.setState({ showScopusDetails: true })
                    }}
                    onMouseLeave={() => {
                      this.setState({ showScopusDetails: false })
                    }}
                  >
                    <i className='fas fa-info-circle source-label' />
                  </span>
                )}
                <div
                  onMouseEnter={() => {
                    this.setState({ showScopusDetails: true })
                  }}
                  onMouseLeave={() => {
                    this.setState({ showScopusDetails: false })
                  }}
                  className={`pubmed-details ${
                    this.state.showScopusDetails ? '' : 'no-show'
                  }`}
                  style={{
                    left: '5.3em',
                    top: '-132px',
                    width: '600px',
                    fontSize: '14px'
                  }}
                >
                  <header>
                    <span
                      className='cancel-x'
                      onClick={() =>
                        this.setState({ showScopusDetails: false })}
                    />
                  </header>
                  <p className='mr-3'>
                    <b>Authentication required</b>
                    <br />
                    <br />
                    <b>If your institution subscribes to Scopus,</b> please
                    email your Digital Commons Consulting Services
                    representative to request that Scopus harvesting be set up.
                    <br />
                    <br />
                    <b>
                      If your institution does not currently subscribe to
                      Scopus,
                    </b>{' '}
                    and you would like to learn more about Scopus harvesting,
                    please click{' '}
                    <a
                      href='https://www.elsevier.com/solutions/digital-commons/why-choose-digital-commons/scopus-integration'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </p>
                  <div className='carot'>
                    <div id='triangle' />
                  </div>
                </div>

                <div
                  className='form-check form-check-inline'
                  disabled={!pureEnabled}
                >
                  <input
                    className='form-check-input mr-2'
                    type='radio'
                    name={SOURCE_PURE}
                    checked={this.state.dataSource === SOURCE_PURE}
                    onChange={this.handleDataSourceChange}
                    data-testid={`radio-${SOURCE_PURE}`}
                  />
                  <label className='form-check-label mr-3'>Pure</label>
                </div>

                {!pureEnabled && (
                  <span
                    style={{ left: '10.6em', position: 'absolute' }}
                    onMouseEnter={() => {
                      this.setState({ showPureDetails: true })
                    }}
                    onMouseLeave={() => {
                      this.setState({ showPureDetails: false })
                    }}
                  >
                    <i className='fas fa-info-circle source-label' />
                  </span>
                )}

                <div
                  onMouseEnter={() => {
                    this.setState({ showPureDetails: true })
                  }}
                  onMouseLeave={() => {
                    this.setState({ showPureDetails: false })
                  }}
                  className={`pubmed-details ${
                    this.state.showPureDetails ? '' : 'no-show'
                  }`}
                  style={{
                    left: '12em',
                    top: '-154px',
                    width: '600px',
                    fontSize: '14px'
                  }}
                >
                  <header>
                    <span
                      className='cancel-x'
                      onClick={() => this.setState({ showPureDetails: false })}
                    />
                  </header>
                  <p className='mr-3'>
                    <b>Authentication required</b>
                    <br />
                    <br />
                    <b>If your institution subscribes to Pure,</b> please
                    generate a Pure API key, and email it to your Digital
                    Commons Consulting Services representative to add to the
                    Harvesting Tool. Contact your Consulting Services
                    representative with any questions.
                    <br />
                    <br />
                    <b>
                      If your institution does not currently subscribe to Pure,
                    </b>{' '}
                    and you would like to learn more, please click{' '}
                    <a
                      href='https://www.elsevier.com/solutions/pure'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </p>
                  <div className='carot'>
                    <div id='triangle' />
                  </div>
                </div>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input mr-2'
                    type='radio'
                    name={SOURCE_ORCID}
                    checked={this.state.dataSource === SOURCE_ORCID}
                    onChange={this.handleDataSourceChange}
                    data-testid={`radio-${SOURCE_ORCID}`}
                  />
                  <label className='form-check-label mr-3'>ORCID</label>
                </div>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input mr-2'
                    type='radio'
                    name={SOURCE_PUBMED}
                    checked={this.state.dataSource === SOURCE_PUBMED}
                    onChange={this.handleDataSourceChange}
                    data-testid={`radio-${SOURCE_PUBMED}`}
                  />
                  <label className='form-check-label mr-3'>
                    PubMed
                    <span
                      onMouseEnter={() => {
                        this.setState({ showPubMedDetails: true })
                      }}
                      onMouseLeave={() => {
                        this.setState({ showPubMedDetails: false })
                      }}
                    >
                      <i className='fas fa-info-circle source-label' />
                    </span>
                  </label>
                </div>
                <div
                  onMouseEnter={() => {
                    this.setState({ showPubMedDetails: true })
                  }}
                  onMouseLeave={() => {
                    this.setState({ showPubMedDetails: false })
                  }}
                  className={`pubmed-details ${
                    this.state.showPubMedDetails ? '' : 'no-show'
                  }`}
                  style={{ left: '19em', top: '-132px' }}
                >
                  <header>
                    <span
                      className='cancel-x'
                      onClick={() =>
                        this.setState({ showPubMedDetails: false })}
                    />
                  </header>
                  <p className='mr-3'>
                    NOTE: Results and metadata harvested from PubMed may differ
                    from those found on the PubMed website due to technical
                    limitations with the PubMed API.
                  </p>
                  <div className='carot'>
                    <div id='triangle' />
                  </div>
                </div>
              </div>
            </div>
            {/* EXPORT STATUS LINK */}
            <div className='col-lg-3 text-right'>
              <button
                data-testid='search-input-export-status'
                onClick={this.context.actions.openExportStatusModal}
                type='button'
                className='primary-link mt-3'
              >
                <u>Export Status</u>
              </button>
            </div>
          </div>

          {this.state.dataSource === SOURCE_SCOPUS && (
            <ScopusForm
              searchParams={this.state.searchParams}
              showAffiliationModal={this.state.showAffiliationModal}
              closeAffiliationModal={() =>
                this.setState({ showAffiliationModal: false })}
              handleChangeSearchParams={this.handleChangeSearchParams}
              affiliationToolTip={affiliationToolTip}
              checkValidForm={(valid) => this.checkValidForm(valid)}
              handleContinueSearch={this.handleContinueSearch}
            />
          )}

          {this.state.dataSource === SOURCE_PURE && (
            <PureForm
              searchParams={this.state.searchParams}
              handleChangeSearchParams={this.handleChangeSearchParams}
              handleContentTypeSearch={this.handleContentTypeSearch}
              showContentTypeModal={this.state.showContentTypeModal}
              closeContentTypeModal={() =>
                this.setState({ showContentTypeModal: false })}
              checkValidForm={this.checkValidForm}
            />
          )}

          {this.state.dataSource === SOURCE_ORCID && (
            <OrcidForm
              searchParams={this.state.searchParams}
              handleChangeSearchParams={this.handleChangeSearchParams}
              checkValidForm={this.checkValidForm}
            />
          )}

          {this.state.dataSource === SOURCE_PUBMED && (
            <PubMedForm
              searchParams={this.state.searchParams}
              handleChangeSearchParams={this.handleChangeSearchParams}
              checkValidForm={this.checkValidForm}
            />
          )}

          {/* continue to add Components here */}
        </div>
        <div className='search-footer'>
          <div
            className={`search-buttons ${
              this.state.initialSearch ? '' : 'left-align'
            }`}
          >
            {!multiOrcidSearch && (
              <button
                data-testid='modify-button'
                disabled={
                  this.context.requestLoading || !this.state.isFormValid
                }
                className={`modify-search small left-icon ${
                  this.state.initialSearch ? 'collapsed' : ''
                }`}
                onClick={() => this.handleModifySearch()}
              >
                <div
                  className={`nav-up ${
                    this.context.openInputUI ? 'rotated' : ''
                  }`}
                />
                {this.context.openInputUI ? '' : 'Modify'} Search
              </button>
            )}
            <button
              disabled={this.state.emptyInputs}
              className={`clear-search clear small ${
                this.context.openInputUI ? '' : 'collapsed'
              }`}
              onClick={() => this.handleClearSearch()}
            >
              Clear
              <div className='cancel-x' />
            </button>

            {!multiOrcidSearch && (
              <button
                data-testid='search-button'
                className={`primary-search small ${
                  this.state.initialSearch ? '' : 'collapsed'
                }`}
                onClick={() => this.handleSubmitSearch()}
                disabled={!this.state.isFormValid}
              >
                Search
                <div className='magnify-glass' />
              </button>
            )}
            {multiOrcidSearch && (
              <>
                <button
                  className={`primary-search small ${
                    this.state.openInputUI ? '' : 'collapsed'
                  }`}
                  onClick={() => this.handleShowExportModal()}
                >
                  Export
                  <div className='document-icon' />
                </button>
                {this.state.showExportModal && (
                  <ModalExport
                    modalExportMsg={this.state.exportModalMsg}
                    showModal={this.state.showExportModal}
                    showExportStatus={
                      this.context.actions.openExportStatusModal
                    }
                    publicationsData={this.state.publicationsData}
                    requestedParams={this.context.requestedParams}
                    closeModal={() => this.setState({ showExportModal: false })}
                    jwt={this.context.jwt}
                    orcidIds={this.state.searchParams.orcidIds}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

SearchStart.contextType = HarvestContext

export default SearchStart

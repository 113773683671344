import React, { useEffect } from 'react'
import InputTextArea from './InputTextArea'
import InputTextField from './InputTextField'

const OrcidForm = (props) => {
  const { orcidId, orcidIds } = props.searchParams
  const { checkValidForm } = props

  const orcidIdDisabled = orcidIds ? 'disabled' : ''
  const orcidIdsDisabled = orcidId ? 'disabled' : ''

  const formValidity = !(
    !(orcidId || orcidIds)
  )

  useEffect(() => {
    // validation conditions
    checkValidForm(formValidity)
  }, [formValidity, checkValidForm])

  const handleChangeInput = (target, value) => {
    props.handleChangeSearchParams({ [target]: value })
  }

  return (
    <div data-testid='form-orcid'>
      <div className='section-row'>
        <div className='section-title display-inline'>Author ID</div>
        <div className='row align-items-end'>
          <div className='orcid-id col-sm-5'>
            <InputTextField
              label='Single ORCID ID'
              example='0123-2345-4567-7890'
              inputId='orcidId'
              inputValue={orcidId || ''}
              disabled={orcidIdDisabled}
              setValue={(value) => { handleChangeInput('orcidId', value) }}
            />
          </div>
          <div className='col-sm-7'>
            <InputTextArea
              label='Export results for multiple ORCID IDs'
              example='Type or paste multiple ORCID IDs, one per line to immediately export results'
              inputId='orcidIds'
              inputValue={orcidIds || []}
              disabled={orcidIdsDisabled}
              setValue={(value) => { handleChangeInput('orcidIds', value) }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrcidForm

import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HarvestUI from './component/HarvestUI.jsx'
import * as Sentry from '@sentry/react'

Sentry.init({ dsn: 'https://a7ff9e9edb434a6bb9c4af24fe72e15f@o22181.ingest.sentry.io/5353931' })

function App () {
  return (
    <Router>
      <Switch>
        <Route path='/:context/:jwt' component={HarvestUI} />
      </Switch>
    </Router>
  )
}

export default App

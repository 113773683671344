import React, { useEffect } from 'react'
import ModalContentType from '../ModalContentType'
import InputTextField from './InputTextField'
import InputDateField from './InputDateField'
import CheckBox from 'rc-checkbox'
import * as DateFns from 'date-fns'

const PureForm = (props) => {
  const {
    authorFirst,
    authorLast,
    authorId,
    orcidId,
    organizationalUnitID,
    contentTypes,
    selectedContentTypes,
    contentFilters,
    createDateFrom,
    createDateTo,
    pubDateFrom,
    pubDateTo
  } = props.searchParams

  const { checkValidForm } = props

  const createDateFromComplete = createDateFrom && createDateFrom.split('-').reduce((prev, current) => prev + current.trim().length, 0) === 8
  const createDateToComplete = createDateTo && createDateTo.split('-').reduce((prev, current) => prev + current.trim().length, 0) === 8
  const pubDateFromComplete = pubDateFrom && pubDateFrom.split('-').reduce((prev, current) => prev + current.trim().length, 0) === 8
  const pubDateToComplete = pubDateTo && pubDateTo.split('-').reduce((prev, current) => prev + current.trim().length, 0) === 8

  const parsedCreateDateFrom = DateFns.parse(createDateFrom, 'yyyy-MM-dd', new Date())
  const isValidCreateDateFrom = DateFns.isMatch(createDateFrom, 'yyyy-MM-dd')

  const parsedCreateDateTo = DateFns.parse(createDateTo, 'yyyy-MM-dd', new Date())
  const isValidCreateDateTo = DateFns.isMatch(createDateTo, 'yyyy-MM-dd')

  const parsedPubDateFrom = DateFns.parse(pubDateFrom, 'yyyy-MM-dd', new Date())
  const isValidPubDateFrom = DateFns.isMatch(pubDateFrom, 'yyyy-MM-dd')

  const parsedPubDateTo = DateFns.parse(pubDateTo, 'yyyy-MM-dd', new Date())
  const isValidPubDateTo = DateFns.isMatch(pubDateTo, 'yyyy-MM-dd')

  const invalidCreateDateFrom = createDateFromComplete ? !isValidCreateDateFrom : false
  const invalidCreateDateTo = createDateToComplete ? !isValidCreateDateTo : false
  const invalidCreateDateRange = (parsedCreateDateFrom && parsedCreateDateTo) &&
    (DateFns.isAfter(parsedCreateDateFrom, parsedCreateDateTo) || DateFns.isBefore(parsedCreateDateTo, parsedCreateDateFrom))

  const invalidPubDateFrom = pubDateFromComplete ? !isValidPubDateFrom : false
  const invalidPubDateTo = pubDateToComplete ? !isValidPubDateTo : false
  const invalidPubDateRange = (isValidPubDateFrom && isValidPubDateTo) &&
        (DateFns.isAfter(parsedPubDateFrom, parsedPubDateTo) || DateFns.isBefore(parsedPubDateTo, parsedPubDateFrom))

  const formValidity = !(
    !(authorLast || authorFirst || authorId || orcidId || organizationalUnitID) ||
    (createDateFrom && !createDateFromComplete) ||
    (createDateTo && !createDateToComplete) ||
    (invalidCreateDateFrom || invalidCreateDateTo || invalidCreateDateRange) ||
    (pubDateFrom && !pubDateFromComplete) ||
    (pubDateTo && !pubDateToComplete) ||
    (invalidPubDateFrom || invalidPubDateTo || invalidPubDateRange)
  )

  useEffect(() => {
    // validation conditions
    checkValidForm(formValidity)
  }, [formValidity, checkValidForm])

  const nameDisabled = (organizationalUnitID || authorId || orcidId) ? 'disabled' : ''
  const orgIdDisabled = (authorLast || authorFirst || authorId || orcidId) ? 'disabled' : ''
  const authorIdDisabled = (authorLast || authorFirst || organizationalUnitID || orcidId) ? 'disabled' : ''
  const orcidIdDisabled = (authorLast || authorFirst || organizationalUnitID || authorId) ? 'disabled' : ''

  const handleChangeInput = (target, value) => {
    props.handleChangeSearchParams({ [target]: value })
  }

  const handleChangeContentFilter = (event) => {
    const { target } = event
    const checked = target.checked
    let filters = []
    if (checked) {
      if (!contentFilters) { filters = [target.name] } else { filters = [...contentFilters, target.name] }
    } else { filters = contentFilters ? contentFilters.filter(f => f !== target.name) : [] }

    props.handleChangeSearchParams({ contentFilters: filters })
  }

  const handleContentTypeSelected = (selected) => {
    const hasSelected = selected.length > 0
    let filters = []
    if (hasSelected) {
      if (!contentFilters) { filters = ['contentType'] } else if (!contentFilters.includes('contentType')) { filters = [...contentFilters, 'contentType'] } else { filters = contentFilters }
    } else { filters = contentFilters ? contentFilters.filter(f => f !== 'contentType') : [] }

    props.handleChangeSearchParams({
      contentFilters: filters,
      selectedContentTypes: selected
    })
    props.closeContentTypeModal()
  }

  const isValidatedChecked = contentFilters && contentFilters.includes('validated')
  const isContentTypeChecked = contentFilters && contentFilters.includes('contentType')

  return (
    <div data-testid='form-pure'>
      <ModalContentType
        showModal={props.showContentTypeModal}
        contentTypes={contentTypes}
        handleContentTypeSelected={handleContentTypeSelected}
        closeModal={() => props.closeContentTypeModal()}
      />
      {/* AUTHOR INPUT */}
      <div className='section-row name-switch'>
        <div className='section-title display-inline'>Author Name</div>
      </div>
      <div className='author-input row'>
        <div className='last-name col-sm-6'>
          <InputTextField
            label='Last Name'
            example='Smith'
            inputId='authorLast'
            inputValue={authorLast || ''}
            disabled={nameDisabled}
            setValue={(value) => { handleChangeInput('authorLast', value) }}
          />
        </div>
        <div className='first-name col-sm-6'>
          <InputTextField
            label='First/Middle Name/Initials'
            example='John, John Lewis, J. L.'
            inputId='authorFirst'
            inputValue={authorFirst || ''}
            disabled={nameDisabled}
            setValue={(value) => { handleChangeInput('authorFirst', value) }}
          />
        </div>
      </div>

      {/* AUTHOR ID INPUT */}
      <div className='section-row'>
        <div className='section-title display-inline'>Author ID</div>
        <div className='row'>
          <div className='scopus-id col-sm-6'>
            <InputTextField
              label='Pure ID'
              example='91234567890'
              inputId='authorId'
              disabled={authorIdDisabled}
              inputValue={authorId || ''}
              setValue={(value) => { handleChangeInput('authorId', value) }}
            />
          </div>
          <div className='orcid-id col-sm-6'>
            <InputTextField
              label='ORCID ID'
              example='0123-2345-4567-7890'
              inputId='orcidId'
              disabled={orcidIdDisabled}
              inputValue={orcidId}
              setValue={(value) => { handleChangeInput('orcidId', value) }}
            />
          </div>
        </div>
      </div>

      {/* ORGANIZATIONAL UNIT INPUT */}
      <div className='section-row'>
        <div className='section-title display-inline'>Organizational Unit ID</div>
        <div className='row'>
          <div className='col-12'>
            <InputTextField
              label='Organizational Unit ID'
              example='91234567890'
              inputId='organizationalUnitID'
              inputValue={organizationalUnitID || ''}
              disabled={orgIdDisabled}
              setValue={(value) => { handleChangeInput('organizationalUnitID', value) }}
            />
          </div>
        </div>
      </div>

      {/* CONTENT SEARCH FILTERS */}
      <div className='section-row'>
        <div className='section-title display-inline'>Content Search Filters</div>
        <div className='row'>
          <div className='col'>
            <CheckBox
              className='checkbox'
              name='validated'
              checked={isValidatedChecked}
              onChange={handleChangeContentFilter}
            />
            <label className='ml-2 filter-label'>Records with "Validated" workflow step</label>
          </div>
          <div className='col'>
            <CheckBox
              className='checkbox'
              name='contentType'
              onChange={handleChangeContentFilter}
              checked={isContentTypeChecked}
            />
            <label className='ml-2 filter-label'>Records of Content Type</label>
            <button
              className='clear-search clear small ml-3'
              onClick={() => props.handleContentTypeSearch()}
            >
              {selectedContentTypes ? `${selectedContentTypes.length} Types Selected` : 'Select Type'}
              <div className='nav-right rotate-right' />
            </button>
          </div>
        </div>
      </div>

      {/* CREATION DATE INPUT */}
      <div className='section-row'>
        <div className='section-title display-inline'>Creation Date in Pure</div>
        <div className='row'>
          <div className='pub-date col-sm-6'>
            <InputDateField
              label='From'
              example='YYYY-MM-DD'
              inputId='createDateFrom'
              inputValue={createDateFrom || ''}
              setValue={(value) => handleChangeInput('createDateFrom', value)}
            />
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidCreateDateFrom && 'Invalid date.'}
              {invalidCreateDateRange && 'Invalid date range.'}
            </div>
          </div>
          <div className='pub-date col-sm-6'>
            <div className='input-text-field' tabIndex='-1'>
              <InputDateField
                label='To'
                example='YYYY-MM-DD'
                inputId='createDateTo'
                inputValue={createDateTo || ''}
                setValue={(value) => handleChangeInput('createDateTo', value)}
              />
            </div>
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidCreateDateTo && 'Invalid date.'}
            </div>
          </div>
        </div>
      </div>

      {/* PUBLICATION DATE INPUT */}
      <div className='section-row'>
        <div className='section-title display-inline'>Publication Dates</div>
        <div className='row'>
          <div className='pub-date col-sm-6'>
            <InputDateField
              label='From'
              example='YYYY-MM-DD'
              inputId='pubDateFrom'
              inputValue={pubDateFrom || ''}
              setValue={(value) => handleChangeInput('pubDateFrom', value)}
            />
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidPubDateFrom && 'Invalid date.'}
              {invalidPubDateRange && 'Invalid date range.'}
            </div>
          </div>
          <div className='pub-date col-sm-6'>
            <div className='input-text-field' tabIndex='-1'>
              <InputDateField
                label='To'
                example='YYYY-MM-DD'
                inputId='pubDateTo'
                inputValue={pubDateTo || ''}
                setValue={(value) => handleChangeInput('pubDateTo', value)}
              />
            </div>
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidPubDateTo && 'Invalid date.'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PureForm

import React, { useContext } from 'react'
import HarvestContext from '../context/HarvestContext'
import useExportStatus from '../hook/ExportStatus'
import * as DateFns from 'date-fns'
import '../../scss/modal.scss'

const ModalExportStatus = () => {
  const context = useContext(HarvestContext)

  if (!context.showExportStatusModal) return ''

  return (
    <div className='modal' id='modal-export-status' style={{ display: 'block' }}>
      <div className='modal-dialog'>
        <ExportStatusResults jwt={context.jwt} handleCancelClick={context.actions.closeExportStatusModal} onError={context.actions.handleExportStatusError} />
      </div>
    </div>
  )
}

const ExportStatusResults = (props) => {
  const [status] = useExportStatus(props.jwt, 5000, props.onError)

  const calculateHours = (minutes) => {
    const hours = minutes / 60

    if (Number.isInteger(hours)) { return hours }

    return (Math.ceil(hours) - hours >= 0.5) ? (Math.floor(hours) + 0.5) : Math.ceil(hours)
  }

  const formatEstimatedDeliveryTime = (seconds) => {
    if (!seconds) return ''
    const minutes = seconds / 60
    if (minutes < 1) return '< 1 minute'
    if (minutes < 60) return `${Math.ceil(minutes)} minutes`
    return `${calculateHours(minutes)} hours`
  }

  const renderExportStatusRows = () => {
    if (status.data) {
      return status.data.map(x => {
        const { name, status, requestDateTime, numRecords, publicationId, identifyDuplicates, estimatedDeliveryTime, batchUploadLink } = x.attributes
        // https://github.com/noplay/json-api-doc/issues/22
        const links = x.attributes.links
        const isSuccessful = status.toLowerCase() === 'success'
        return (
          <tr key={x.id}>
            <td>{isSuccessful ? <a href={links.results}>{name}</a> : name}</td>
            <td>{status}</td>
            <td>{DateFns.format(DateFns.fromUnixTime(requestDateTime), 'MMMM do, yyyy p')}</td>
            <td className='text-right'>{numRecords}</td>
            <td>{isSuccessful ? <a href={batchUploadLink} target='_blank' rel='noopener noreferrer'>{publicationId}</a> : publicationId}</td>
            <td className='text-center'>{identifyDuplicates ? 'Y' : 'N'}</td>
            {/* pending implementation of SHOW-652 */}
            <td style={{ display: 'none' }}>{formatEstimatedDeliveryTime(estimatedDeliveryTime)}</td>
          </tr>
        )
      })
    }
  }

  return (
    <div className='dialog-box modal-content'>
      <hr />
      <div className='dialog-text pl-4 my-2 modal-header border-bottom-0'>
        <div className='export-status-title row'>
          <div className='cancel-x' onClick={() => props.handleCancelClick()} />
        </div>
        <div className='export-status-detail mt-4 row'>
          <h4 className='w-100'>Export Status</h4>
          <p>NOTE: Export jobs are deleted 72 hours after request</p>
        </div>
      </div>
      <div className='dialog-table modal-body mx-3'>
        <table className='export-status-table w-100'>
          <thead>
            <tr className='border-bottom'>
              <th>Name</th>
              <th>Status</th>
              <th>Request Date</th>
              <th># of Records</th>
              <th>Publication</th>
              <th>Duplication Check (Y/N)</th>
              {/* pending implementation of SHOW-652 */}
              <th style={{ display: 'none' }}>Estimated Delivery</th>
            </tr>
          </thead>
          <tbody>
            {renderExportStatusRows()}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ModalExportStatus

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HarvestContext from '../context/HarvestContext'
import { trackNewPageEvent } from '../../analytics'
import '../../scss/modal.scss'

class ModalAffiliations extends Component {
  constructor (props) {
    super(props)
    this.state = { selectedAffiliations: [] }
    this.prevSelected = this.prevSelected.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      if (!prevProps.showModal && this.props.showModal) {
        this.context.loadTracker.stopTracking()
        trackNewPageEvent(`harvest:${this.props.dataSource}:search:affiliation selection`, this.context.loadTracker.loadTime)
      }

      if (this.props.selectedAffiliations) {
        this.setState({ selectedAffiliations: this.props.selectedAffiliations })
      }
    }
  }

  handleCancelClick () {
    this.setState({
      selectedAffiliations: []
    })
    this.props.closeModal()
  }

  handleSearchClick () {
    this.props.handleContinueSearch()
  }

  handleSelectChange (affiliationId) {
    if (!this.prevSelected(affiliationId)) {
      this.setState({
        selectedAffiliations: [...this.state.selectedAffiliations, affiliationId]
      })
    } else {
      // apparently the fastest way to remove items from a small array in js
      const filteredAffiliations = []
      for (let i = 0; i < this.state.selectedAffiliations.length; i++) {
        if (this.state.selectedAffiliations[i] !== affiliationId) {
          filteredAffiliations.push(this.state.selectedAffiliations[i])
        }
      }
      this.setState({
        selectedAffiliations: filteredAffiliations
      })
    }
    // push it up to parent state too
    this.props.handleSelectAffiliation(affiliationId)
  }

  prevSelected (affiliationId) {
    return this.state.selectedAffiliations.indexOf(affiliationId) > -1
  }

  renderAffiliationsModal () {
    const { data, meta } = this.props.affiliationsData
    return (
      <div className='modal' id='modal-affiliations' style={{ display: 'block' }}>
        <div className='modal-dialog'>
          {data.length > 0 ? this.renderAffiliationsResults(data, meta) : this.renderEmptyResults(this.props.affiliationSearchParam)}
        </div>
      </div>
    )
  }

  renderAffiliationsResults (affiliations, meta) {
    const enabled = this.state.selectedAffiliations.length > 0
    const affiliationCount = affiliations ? affiliations.length : 0
    return (
      <div className='dialog-box modal-content'>
        <hr />
        <div className='dialog-text pl-4 my-2 modal-header'>
          <div className='affiliations-title'>
            <div className='cancel-x' onClick={() => this.handleCancelClick()} />
            Affiliation Selection
          </div>
          <div className='affiliations-detail'>
            Select one or more affiliations from Scopus's database
            {meta.result_count > 100 && <p><small className='text-danger'>Showing only 100 of {meta.result_count} matches for {this.props.affiliationSearchParam}. Consider refining your affiliation input.</small></p>}

          </div>
        </div>
        <div className='dialog-table modal-body'>
          <table className='affiliation-table'>
            <thead>
              <tr className='border-bottom'>
                <th />
                <th>Affiliation ({affiliationCount})</th>
                <th>City</th>
                <th className='pr-3'>Country/Territory</th>
              </tr>
            </thead>
            <tbody>
              {this.renderAffiliationRows(affiliations)}
            </tbody>
          </table>
        </div>
        <div className='dialog-footer border-top modal-footer'>
          <button className='cancel-button mr-3 my-2' onClick={() => this.handleCancelClick()}>
            Cancel
          </button>
          <button className={`affiliations-button complete my-2 ${!enabled && 'disabled'}`} onClick={() => this.handleSearchClick()} disabled={!enabled}>
            Continue to search
            <div className='magnify-glass' />
          </button>
        </div>
      </div>
    )
  }

  renderEmptyResults () {
    return (
      <div className='dialog-box modal-content'>
        <hr />
        <div className='dialog-text ml-4 my-2 modal-header border-bottom-0'>
          <div className='affiliations-title'>
            <div className='cancel-x' onClick={() => this.handleCancelClick()} />
          </div>
        </div>
        <div className='modal-body'>
          <div className='dialog-img inline'>
            <div className='tool-kit' />
          </div>
          <div className='dialog-text inline'>
            <div className='error-title'>
              No Scopus affiliations matching {this.props.affiliationSearchParam} were found.
            </div>
          </div>
        </div>

        <div className='dialog-footer border-top modal-footer'>
          <button className='back-button complete my-2' onClick={() => this.handleCancelClick()}>
            Back to search options
          </button>
        </div>
      </div>
    )
  }

  renderAffiliationRows (affiliations) {
    return (
      affiliations.map(affiliation => {
        const { id, attributes } = affiliation
        return (
          <tr key={id}>
            <td className='text-center px-2 pt-3'>
              <div className='radiobutton-off' key={id} onClick={() => this.handleSelectChange(id)}>
                <div className={this.prevSelected(id) ? 'radiobutton-selected' : ''} />
              </div>
            </td>
            <td className='pr-3 pt-3'>
              {attributes.Name} <br /> <small>{attributes.NameVariant}</small>
            </td>
            <td className='pr-5 pt-3'>{attributes.City}</td>
            <td className='pt-3'>{attributes.Country}</td>
          </tr>
        )
      })
    )
  }

  render () {
    if (!this.props.showModal) {
      return ''
    } else {
      return (this.renderAffiliationsModal())
    }
  }
}

ModalAffiliations.contextType = HarvestContext

export default ModalAffiliations

ModalAffiliations.propTypes = {
  showModal: PropTypes.bool,
  handleContinueSearch: PropTypes.func,
  handleSelectAffiliation: PropTypes.func,
  affiliationSearchParam: PropTypes.string,
  selectedAffiliations: PropTypes.array,
  closeModal: PropTypes.func,
  dataSource: PropTypes.string
}

export const SEARCH_TYPE_AUTHOR = 'bpdg:harvest:author'
export const SEARCH_TYPE_AFFILIATION = 'bpdg:harvest:affiliation'

export function trackNewPageEvent (pageName, loadTime) {
  window.pageData = generateBasePageData()
  window.pageDataTracker.trackEvent('newPage', {
    page: {
      name: pageName,
      loadTime: loadTime.toString(),
      type: getPageType(pageName)
    }
  })
}

export function trackSearchCriteria (searchCriteria, params) {
  const { pageDataTracker } = window
  pageDataTracker.trackEvent('searchResultsUpdated', {
    search: {
      type: getSearchType(params),
      criteria: searchCriteria,
      facets: [
        {
          name: 'contentType',
          values: [params.contentFilters?.includes('contentType') ? 'True' : '']
        },
        {
          name: 'validated',
          values: [params.contentFilters?.includes('validated') ? 'True' : '']
        }
      ],
      database: params.dataSource // evar 117
    }
  })
}

export function trackLinkOut (id, text, address, dataSource) {
  window.pageDataTracker.trackEvent('linkOut', {
    content: [{
      id: `doi:${id}`,
      linkOut: 'external:doi.org',
      type: `bpdg:harvest:${dataSource}:search-result`
    }],
    link: {
      location: 'result-item',
      name: text,
      type: 'exit',
      destination: address
    }
  })
}

export function trackExportEvent (id, contentType, exportType) {
  window.pageData = generateBasePageData()
  window.pageDataTracker.trackEvent('contentExport', {
    content: [{
      id: id,
      type: contentType,
      format: 'MIME-XLS',
      exportType: exportType
    }]
  })
}

export function trackFormErrorEvent (errorStatus) {
  window.pageData = generateBasePageData()
  window.pageDataTracker.trackEvent('formError', {
    form: {
      errorType: 'se-' + errorStatus
    }
  })
}

export function trackNavigationClick (location, name) {
  window.pageDataTracker.trackEvent('navigationClick', {
    link: {
      location: location,
      name: name
    }
  })
}

export function generateInitialPageData () {
  const claims = getClaims()

  return {
    page: {
      environment: process.env.REACT_APP_ELS_ADOBE_ANALYTICS_ENV,
      name: 'page-init',
      type: 'IN-CP',
      productName: 'bpdg',
      businessUnit: 'els:rp:st',
      loadTimestamp: '' + new Date().getTime(),
      noTracking: '' + ('trackUser' in claims && !claims.trackUser)
    },
    visitor: 'accessType' in claims ? claims : { accessType: 'bpdg:ANON_GUEST' }
  }
}

export function generateBasePageData () {
  const claims = getClaims()

  return {
    page: {
      environment: process.env.REACT_APP_ELS_ADOBE_ANALYTICS_ENV,
      name: window.pageData.page.name,
      productName: 'bpdg',
      businessUnit: 'els:rp:st',
      language: 'en',
      loadTimestamp: '' + new Date().getTime(),
      noTracking: '' + ('trackUser' in claims && !claims.trackUser)
    },
    visitor: 'accessType' in claims ? claims : { accessType: 'bpdg:ANON_GUEST' }
  }
}

export class LoadTracker {
  constructor () {
    this.loadStart = null
    this.loadTime = null
  }

  startTracking () {
    this.loadStart = Date.now()
  }

  stopTracking () {
    this.loadTime = Date.now() - this.loadStart
  }
}

function getClaims () {
  const claims = parseJwt(getToken())

  if (claims) {
    delete claims.iss
    delete claims.sub
  }

  return claims
}

export function parseJwt (c) {
  try {
    const b = c.split('.')[1]
    const a = b.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(a))
  } catch (err) {
    return {}
  }
}

function getToken () {
  const container = document.getElementsByClassName('container')[0]
  const url = container ? new URL(container.children[0].src) : window.location

  return (url.pathname.split('/')[2])
}

function getPageType (pageName) {
  switch (true) {
    case /harvest:.*:search$/.test(pageName):
      return 'SP-SR'
    case /harvest:.*:search:results/.test(pageName):
      return 'SP-ST'
    case /harvest:.*:search:affiliation selection/.test(pageName):
      return 'SP-ST'
    case /harvest:search:.*:facet:content-type/.test(pageName):
      return 'SP-FS'
    default:
      return undefined
  }
}

function getSearchType (params) {
  let type = ''

  // a search with any date range is automatically a combined-search
  // bc date-only searches are currently not allowed
  const isCombined = (params.createDateFrom || params.createDateTo || params.pubYearFrom || params.pubYearTo || params.pubDateFrom || params.pubDateTo)
  if (isCombined) { type = 'combined-search' } else {
    if (params.firstName || params.lastName) { type = 'author' } else if (params.authorId || params.orcidId) { type = 'author-id' }
    // affiliation search (modal) || selected affiliations search
    else if (params.authorAffiliation || params.affiliationID) { type = 'affiliation' } else if (params.organizationalUnitID) { type = 'org-unit' }
  }

  return `bpdg:harvest:${params.dataSource}:${type}`
}

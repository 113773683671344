import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HarvestContext from '../context/HarvestContext';
import Checkbox from 'rc-checkbox';
import CustomScroll from 'react-custom-scroll';
import HarvestApiService from '../service/HarvestApi.jsx';
import { trackExportEvent, trackNewPageEvent } from '../../analytics';
import '../../scss/modal.scss';
import 'react-custom-scroll/dist/customScroll.css';

function ModalExport({
  modalExportMsg,
  showModal,
  showExportStatus,
  publicationsData,
  requestedParams,
  totalResults,
  closeModal,
  orcidIds,
  jwt,
}) {
  const context = useContext(HarvestContext);

  const [includeUnmappedMetadata, setIncludeUnmappedMetadata] = useState(false);
  const [includeSherpaRomeo, setIncludeSherpaRomeo] = useState(false);
  const [identifyDuplicatesInPub, setIdentifyDuplicatesinPub] = useState(false);
  const [identifyDuplicatesInPubType, setIdentifyDuplicatesInPubType] =
    useState(false);
  const [selectedPublicationId, setSelectedPublicationId] = useState('');
  const [selectedPublicationKey, setSelectedPublicationKey] = useState('');
  const [selectedPublicationType, setSelectedPublicationType] = useState('');
  const [publicationList, setPublicationList] = useState([]);

  useEffect(() => {
    context.loadTracker.stopTracking();
    trackNewPageEvent(
      `harvest:${requestedParams.dataSource}:export options`,
      context.loadTracker.loadTime
    );
  }, [requestedParams, context.loadTracker]);

  function handleExportClick() {
    const isMultiOrcidExport = orcidIds && orcidIds.length > 0;
    if (!isMultiOrcidExport) {
      const requestedParams = context.requestedParams;
      requestedParams.includeUnmappedMetadata = includeUnmappedMetadata;
      requestedParams.includeSherpaRomeo = includeSherpaRomeo;
      requestedParams.identifyDuplicates = identifyDuplicatesInPub
        ? 'selectedPub'
        : identifyDuplicatesInPubType
        ? 'selectedPubType'
        : '';

      requestedParams.selectedPublicationId = selectedPublicationId;
      requestedParams.totalResults = totalResults;
      requestedParams.publicationType = identifyDuplicatesInPubType
        ? selectedPublicationType
        : null;
      requestedParams.siteKey = identifyDuplicatesInPubType
        ? context.siteContext
        : null;
      requestedParams.siteUrl = context.siteUrl;

      HarvestApiService.getExport(
        selectedPublicationKey,
        requestedParams,
        jwt,
        (results) => {
          closeModal();
          showExportStatus();
          trackExportResult(results.data, requestedParams);
        },
        (error) => {
          closeModal();
          if (error.response?.data.errors) {
            const title = error.response.data.errors[0].title;
            const detail = error.response.data.errors[0].detail;
            context.actions.openErrorModal(title, detail);
          } else {
            const title = 'Internal Server Error';
            const detail =
              'If this problem persists, contact your Consulting Services representative.';
            context.actions.openErrorModal(title, detail);
          }
        }
      );
    } else {
      const data = {
        dataSource: 'orcid',
        publicationId: selectedPublicationId,
        orcid: orcidIds,
        includeUnmappedMetadata: includeUnmappedMetadata,
        includeSherpaRomeo: includeSherpaRomeo,
        identifyDuplicates: identifyDuplicatesInPub
          ? 'selectedPub'
          : identifyDuplicatesInPubType
          ? 'selectedPubType'
          : '',
        publicationType: identifyDuplicatesInPubType
          ? selectedPublicationType
          : null,
        siteKey: context.siteContext,
      };
      HarvestApiService.postMultiOrcidExport(
        selectedPublicationKey,
        data,
        jwt,
        (results) => {
          closeModal();
          modalExportMsg.confirmClick();
          showExportStatus();
          // trackExportResult(results.data, requestedParams)
        },
        (error) => {
          closeModal();
          if (error.response?.data.errors) {
            const title = error.response.data.errors[0].title;
            const detail = error.response.data.errors[0].detail;
            context.actions.openErrorModal(title, detail);
          } else {
            const title = 'Internal Server Error';
            const detail =
              'If this problem persists, contact your Consulting Services representative.';
            context.actions.openErrorModal(title, detail);
          }
        }
      );
    }

    // reposition back to top of page
    window.scrollTo(0, 0);
  }

  function trackExportResult(result, requestedParams) {
    const { name } = result.data.attributes;
    const exportId = `bpdg:${name.replace('.xls', '')}`;
    const contentType = `bpdg:harvest:search-result:${requestedParams.dataSource}`;
    const exportType = mapExportType(
      requestedParams.includeUnmappedMetadata,
      requestedParams.identifyDuplicates,
      requestedParams.includeSherpaRomeo
    );
    trackExportEvent(exportId, contentType, exportType);
  }

  function mapExportType(
    includeUnmappedMetadata,
    identifyDuplicates,
    includeSherpaRomeo
  ) {
    const opts = [];

    if (includeUnmappedMetadata) {
      opts.push('includeUnmappedMetadata');
    }

    if (includeSherpaRomeo) {
      opts.push('includeSherpaRomeo');
    }

    switch (identifyDuplicates) {
      case 'selectedPub':
        opts.push('identifyDuplicatesInSelectedPub');
        break;
      case 'selectedPubType':
        opts.push('identifyDuplicatesInAllPub');
        break;
      default:
        break;
    }

    return opts.some((e) => e) ? 'file'.concat(':', opts.join('&')) : 'file';
  }

  function handlePublicationTypeClick(publicationType, publications) {
    setSelectedPublicationType(publicationType);
    setPublicationList(publications);
  }

  function handlePublicationClick(publicationKey, publicationId, publications) {
    setSelectedPublicationKey(publicationKey);
    setSelectedPublicationId(publicationId);
    setPublicationList(publications);
  }

  function handleExportMetadataCheckbox(event) {
    setIncludeUnmappedMetadata(event.target.checked);
  }

  function handleExportSherpaRomeoCheckbox(event) {
    setIncludeSherpaRomeo(event.target.checked);
  }

  function handleExportDuplicatesCheckbox(event) {
    setIdentifyDuplicatesinPub(event.target.checked);
  }

  function handleExportDuplicatesByTypeCheckbox(event) {
    setIdentifyDuplicatesInPubType(event.target.checked);
  }

  function renderExportOptions() {
    return (
      <div id='modal-export'>
        <div className='dialog-box'>
          <hr />
          <div className='dialog-text inline'>
            <div className='export-title'>
              <div className='cancel-x' onClick={closeModal} />
              Export options
            </div>
            <div className='export-detail'>
              Select an export format by <b>Publication</b>
            </div>
          </div>
          <div className='publication-columns'>
            <div className='publication-types col-sm-3'>
              <div className='publication-type-list-title'>
                1. Publication Type
              </div>
              <CustomScroll heightRelativeToParent='250px'>
                <form>
                  <ul className='publication-type-list'>
                    {/* {renderPublicationTypes()} */}
                    <PublicationTypes
                      publicationsData={publicationsData}
                      selectedPublicationType={selectedPublicationType}
                      handlePublicationTypeClick={handlePublicationTypeClick}
                    />
                  </ul>
                </form>
              </CustomScroll>
            </div>
            <div className='publications col-sm-5'>
              <div className='publication-list-title'>2. Publication</div>
              <ul className='publication-list'>
                <PublicationList
                  publications={publicationList}
                  selectedPublicationKey={selectedPublicationKey}
                  selectedPublicationId={selectedPublicationId}
                  handlePublicationClick={handlePublicationClick}
                />
              </ul>
            </div>
            <div className='options col-sm-4 px-0'>
              {selectedPublicationType && (
                <>
                  <div className='options-title'>3. Options</div>
                  <div className='checkbox-container'>
                    <Checkbox
                      className='checkbox'
                      onChange={handleExportMetadataCheckbox}
                    />
                    <div className='checkbox-label'>
                      <i>
                        Include additional unmapped metadata from source in
                        export
                        <br />
                        (requires modifying/renaming/deleting export spreadsheet
                        columns)
                      </i>
                    </div>
                  </div>
                  <div className='checkbox-container'>
                    <Checkbox
                      className='checkbox'
                      disabled={identifyDuplicatesInPubType}
                      onChange={handleExportDuplicatesCheckbox}
                    />
                    <div
                      className={`checkbox-label ${
                        identifyDuplicatesInPubType
                          ? 'checkbox-label-disabled'
                          : ''
                      }`}
                    >
                      <i>
                        Identify likely duplicates between result set and{' '}
                        <strong>
                          selected {renderDuplicatesCheckboxPrompt()}
                        </strong>
                        <br />
                        (may affect export preparation time)
                      </i>
                    </div>
                  </div>
                  <div className='checkbox-container'>
                    <Checkbox
                      className='checkbox'
                      disabled={identifyDuplicatesInPub}
                      onChange={handleExportDuplicatesByTypeCheckbox}
                    />
                    <div
                      className={`checkbox-label ${
                        identifyDuplicatesInPub ? 'checkbox-label-disabled' : ''
                      }`}
                    >
                      <i>
                        Identify likely duplicates between result set and{' '}
                        <strong>
                          all {renderDuplicatesByTypeCheckboxPrompt()}
                        </strong>{' '}
                        <br />
                        (may significantly affect export preparation time)
                      </i>
                    </div>
                  </div>
                  <div className='checkbox-container'>
                    <Checkbox
                      className='checkbox'
                      onChange={handleExportSherpaRomeoCheckbox}
                    />
                    <div className='checkbox-label'>
                      <i>
                        Include{' '}
                        <a
                          href='https://www.jisc.ac.uk/website/copyright?loc=cc'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Sherpa-Romeo
                        </a>{' '}
                        journal permission information in export
                        <br />
                        (requires deleting export spreadsheet columns)
                      </i>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {selectedPublicationType && (
            <div className='bottom-row'>
              <button className='confirm' onClick={closeModal}>
                Cancel
              </button>
              <button
                className='export-button'
                disabled={selectedPublicationKey ? '' : 'disabled'}
                onClick={handleExportClick}
              >
                Export
                <div className='document-icon' />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderDuplicatesCheckboxPrompt() {
    switch (selectedPublicationType) {
      case 'ir_book':
        return 'book gallery';
      case 'ir_etd':
        return 'ETD series';
      case 'ir_gallery':
        return 'image gallery';
      case 'ir_journal':
        return 'journal';
      case 'ir_series':
        return 'series';
      case 'ir_event_community':
        return 'event community';
      default:
        return '';
    }
  }

  function renderDuplicatesByTypeCheckboxPrompt() {
    switch (selectedPublicationType) {
      case 'ir_book':
        return 'book galleries';
      case 'ir_etd':
        return 'ETD series';
      case 'ir_gallery':
        return 'image galleries';
      case 'ir_journal':
        return 'journals';
      case 'ir_series':
        return 'series';
      case 'ir_event_community':
        return 'event communities';
      default:
        return '';
    }
  }

  return renderExportOptions();
}

function PublicationList({
  publications,
  selectedPublicationKey,
  selectedPublicationId,
  handlePublicationClick,
}) {
  return Object.entries(publications)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map((entry) => {
      const [key, publication] = entry;
      return (
        <li
          className='publication-type row m-0'
          key={key}
          onClick={() => {
            handlePublicationClick(key, publication, publications);
          }}
        >
          <div className='radiobutton-off'>
            <div
              className={
                selectedPublicationKey === key ? 'radiobutton-selected' : ''
              }
            />
          </div>
          <span
            className={
              selectedPublicationKey === key
                ? 'list-text-selected'
                : 'list-text'
            }
          >
            {publication}
          </span>
        </li>
      );
    });
}

// TODO: will clean this up in SHOW-137
function PublicationTypes({
  publicationsData,
  selectedPublicationType,
  handlePublicationTypeClick,
}) {
  return Object.keys(publicationsData.data.data.attributes.publications).map(
    (publicationType, index) => {
      return (
        <li
          className='publication-type row m-0'
          key={index}
          onClick={() =>
            handlePublicationTypeClick(
              publicationType,
              publicationsData.data.data.attributes.publications[
                publicationType
              ]
            )
          }
        >
          <div className='radiobutton-off'>
            <div
              className={
                selectedPublicationType === publicationType
                  ? 'radiobutton-selected'
                  : ''
              }
            />
          </div>
          <span
            className={
              selectedPublicationType === publicationType
                ? 'list-text-selected'
                : 'list-text'
            }
          >
            {publicationType}
          </span>
        </li>
      );
    }
  );
}

ModalExport.propTypes = {
  modalExportMsg: PropTypes.object,
  showModal: PropTypes.bool,
  showExportStatus: PropTypes.func,
  totalResults: PropTypes.number,
  closeModal: PropTypes.func,
  jwt: PropTypes.string,
};

export default ModalExport;

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../../../scss/form/input-text-field.scss'

class InputTextField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      onFocus: false,
      inputValue: this.props.inputValue
    }
    this.handleChangeInput = this.handleChangeInput.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    return nextProps
  }

  handleChangeInput (event) {
    this.setState({ inputValue: event.target.value })
    this.props.setValue(event.target.value)
  }

  render () {
    return (
      <div disabled={this.props.disabled} className='input-text-field' tabIndex={this.props.tabIndex}>
        <label htmlFor={this.props.inputId} className={this.state.inputValue || this.state.onFocus ? '' : 'placeholder-text'}>
          {this.props.label} <span className='example-text'>e.g. {this.props.example}</span>
        </label>
        <input
          id={this.props.inputId}
          type='search'
          tabIndex={this.props.disabled ? '-1' : '1'}
          onChange={this.handleChangeInput}
          onFocus={() => this.setState({ onFocus: true })}
          onBlur={() => this.setState({ onFocus: false })}
          value={this.state.inputValue}
          autoComplete={this.props.autoComplete}
        />
      </div>
    )
  }
}

export default InputTextField

InputTextField.propTypes = {
  inputId: PropTypes.string,
  tabIndex: PropTypes.number,
  label: PropTypes.string,
  example: PropTypes.string,
  autoComplete: PropTypes.string // on or off
}

InputTextField.defaultProps = {
  inputId: 'default',
  tabIndex: -1,
  label: '',
  example: '',
  autoComplete: 'on'
}

import React, { useContext, useState } from 'react'
import HarvestContext from '../context/HarvestContext'
import '../../scss/harvest-search-status.scss'
import * as DateFns from 'date-fns'

const SearchStatusUI = () => {
  const context = useContext(HarvestContext)
  const [showAllAffiliations, toggleShowAllAffiliations] = useState(false)

  const {
    resultsMetaData,
    requestedParams,
    requestLoading,
    openInputUI
  } = context

  const showStatus = Object.keys(requestedParams).length > 0 && Object.keys(resultsMetaData).length > 0 && !openInputUI
  const pageStart = resultsMetaData.page_start
  const pageEnd = resultsMetaData.page_end
  const resultCount = resultsMetaData.result_count
  const totalResults = resultsMetaData.total_results
  const showTruncatedWarning = resultCount > 5000

  if (!showStatus) return ''

  let name = requestedParams.lastName
  if (requestedParams.firstName) {
    name += `, ${requestedParams.firstName}`
  }
  let status = ''
  if (requestLoading) {
    status = 'Searching...'
  } else if (totalResults === 0) {
    status = 'No results for:'
  } else {
    status = `${pageStart.toLocaleString()}-${pageEnd.toLocaleString()} of ${totalResults.toLocaleString()} results for:`
  }

  const formatAuthorIDLabel = () => {
    switch (requestedParams.dataSource) {
      case 'scopus':
        return 'Scopus ID:'
      case 'pure':
        return 'PURE ID:'
      default:
        return ''
    }
  }

  return (
    <div id='search-status-ui' className='row'>
      <div className='col-sm-6 results-for'>
        {status}
        <div className={`helptext-small error pl-0 ${showTruncatedWarning ? '' : 'error-no-show'}`}>
          <span className='attention'>Search results truncated.</span> Your search found {resultCount.toLocaleString()} results. A maximum of 5,000 results can be viewed and exported. Consider refining the search.
        </div>
      </div>
      <ol className='col-sm-3 param-labels'>
        {name ? (<li>Author:</li>) : ''}
        {requestedParams.authorId ? (<li>{formatAuthorIDLabel()}</li>) : ''}
        {requestedParams.orcidId ? (<li>ORCID ID:</li>) : ''}
        {requestedParams.authorAffiliation ? (<li>Affiliation:</li>) : ''}
        {requestedParams.organizationalUnitID ? (<li>Organizational Unit ID:</li>) : ''}
        {(requestedParams.createDateFrom || requestedParams.createDateTo) ? (<li style={{ marginBottom: '30px' }}>Creation Date:</li>) : ''}
        {(requestedParams.pubYearFrom || requestedParams.pubYearTo) ? (<li style={{ marginBottom: '30px' }}>Date Range:</li>) : ''}
        {(requestedParams.pubDateFrom || requestedParams.pubDateTo) ? (<li style={{ marginBottom: '30px' }}>Publication Date:</li>) : ''}
        {requestedParams.contentFilters?.length > 0 ? (<li style={{ marginBottom: '30px' }}>Content Filters:</li>) : ''}
      </ol>
      <ol className='col-sm-3 params'>
        {name ? (<li className='capitalize'>{name}</li>) : ''}
        {requestedParams.authorId ? (<li>{requestedParams.authorId}</li>) : ''}
        {requestedParams.orcidId ? (<li>{requestedParams.orcidId}</li>) : ''}
        {requestedParams.authorAffiliation ? (<li>{requestedParams.authorAffiliation}</li>) : ''}
        {requestedParams.selectedAffiliations ? (<li><SelectedAuthorAffiliationsList affiliations={requestedParams.selectedAffiliations} showAllAffiliations={showAllAffiliations} onShowAllAffiliations={() => toggleShowAllAffiliations(true)} /></li>) : ''}
        {requestedParams.organizationalUnitID ? <li>{requestedParams.organizationalUnitID}</li> : ''}
        {(requestedParams.createDateFrom || requestedParams.createDateTo) ? (<li><CreationDateList from={requestedParams.createDateFrom} to={requestedParams.createDateTo} /></li>) : ''}
        {(requestedParams.pubYearFrom || requestedParams.pubYearTo) ? (<li><DateRangeList from={requestedParams.pubYearFrom} to={requestedParams.pubYearTo} /></li>) : ''}
        {(requestedParams.pubDateFrom || requestedParams.pubDateTo) ? (<li><PubDateRangeList from={requestedParams.pubDateFrom} to={requestedParams.pubDateTo} /></li>) : ''}
        {requestedParams.contentFilters?.length > 0 ? (<li><ContentFiltersList filters={requestedParams.contentFilters} contentTypes={requestedParams.contentTypes} /></li>) : ''}
      </ol>
    </div>
  )
}

const SelectedAuthorAffiliationsList = ({ affiliations, showAllAffiliations, onShowAllAffiliations }) => {
  return (
    <>
      <ul className='pl-0'>
        {
          affiliations.slice(0, 5).map(a => {
            const [affiliationID, name] = a
            return (
              <li key={affiliationID}>
                <p className='affiliation-name mb-0'>{name} <span className='affiliation-id'>{`(Scopus Affiliation ID: ${affiliationID})`}</span></p>
              </li>
            )
          })
        }
        <li>
          {affiliations.length > 5 &&
            <button onClick={onShowAllAffiliations} className='more-affiliations'>
              more affiliations
              <div className='affiliation-up' />
            </button>}

          {showAllAffiliations &&
            <div className='all-affiliations-window'>
              <div className='carot'>
                <div id='triangle' />
              </div>
              <header>{affiliations.length} Affiliations <span className='cancel-x' onClick={onShowAllAffiliations} /></header>
              <div className='affiliations-header row'>
                <div className='col-sm-12'>Affiliation</div>
              </div>
              <ul className='all-affiliations-list'>
                {
                  affiliations.slice(5).map((a) => {
                    const [affiliationID, name] = a
                    return (
                      <li key={a.id}><p className='affiliation-name mb-0'>{name} <span className='affiliation-id'>{`(Scopus Affiliation ID: ${affiliationID})`}</span></p></li>
                    )
                  })
                }
              </ul>
            </div>}
        </li>
      </ul>
    </>
  )
}

const ContentFiltersList = ({ filters, contentTypes }) => {
  const [showContentTypes, toggleShowContentTypes] = useState(false)

  const formatFilterDisplay = (filter) => {
    switch (filter) {
      case 'validated':
        return 'Records with "Validated" work flow step'
      case 'contentType':
        return (
          <>
            Records of Content Type <br />
            {renderContentTypes(contentTypes.slice(0, 5))}
            {contentTypes.length > 5 &&
              <button onClick={() => toggleShowContentTypes(!showContentTypes)} className='content-types'>
                View more
              </button>}
            {showContentTypes &&
              <div className='all-content-types'>
                <div className='carot'>
                  <div id='triangle' />
                </div>
                <header><span className='cancel-x' onClick={() => toggleShowContentTypes(false)} /></header>
                {renderContentTypes(contentTypes.slice(5))}
              </div>}
          </>
        )
      default:
        return ''
    }
  }

  const renderContentTypes = (types) => {
    return (
      <ul className='all-content-types-list pl-0'>
        {
          types.map((c) => {
            return (
              <li key={c.uri}><p className='content-type-name mb-0'>{!c.category ? c.name : `${c.category} - "${c.name}"`}</p></li>
            )
          })
        }
      </ul>
    )
  }

  return (
    <ul className='pl-0'>
      {
        filters.map((filter) => {
          return (
            <li key={filter}>
              <p className='mb-0'>{formatFilterDisplay(filter)}</p>
            </li>
          )
        })
      }
    </ul>
  )
}

const CreationDateList = ({ from, to }) => {
  const formatDateDisplay = (date) => {
    return DateFns.format(DateFns.parseISO(date), 'yyyy-MM-dd')
  }

  return (
    <ul className='pl-0'>
      {from &&
        <li>
          From: {formatDateDisplay(from)}
        </li>}
      {to &&
        <li>
          To: {formatDateDisplay(to)}
        </li>}
    </ul>
  )
}

const DateRangeList = ({ dataSource, from, to }) => {
  return (
    <ul className='pl-0'>
      {from &&
        <li>
          From: {from}
        </li>}
      {to &&
        <li>
          To: {to}
        </li>}
    </ul>
  )
}

const PubDateRangeList = ({ from, to }) => {
  const formatDateDisplay = (date) => {
    return DateFns.format(DateFns.parseISO(date), 'yyyy-MM-dd')
  }

  return (
    <ul className='pl-0'>
      {from &&
        <li>
          From: {formatDateDisplay(from)}
        </li>}
      {to &&
        <li>
          To: {formatDateDisplay(to)}
        </li>}
    </ul>
  )
}

export default SearchStatusUI

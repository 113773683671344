import HarvestContext from './HarvestContext'
import React, { Component } from 'react'
import { parseJwt, trackFormErrorEvent, LoadTracker } from '../../analytics'
import HarvestApiService from '../service/HarvestApi'

// this component initializes the state stored in HarvestContext
// and methods that alter state
class HarvestProvider extends Component {
  constructor (props) {
    super(props)

    this.state = {
      siteContext: '',
      jwt: '',
      siteUrl: '',
      requestedParams: {},
      searchResults: {},
      resultsMetaData: {},
      affiliationsResults: [],
      openInputUI: true,
      requestLoading: false,
      showExportStatusModal: false,
      modalErrorMsg: { title: '', detail: '' },
      showErrorModal: false,
      loadTracker: new LoadTracker()
    }

    this.authorHarvestSearch = this.authorHarvestSearch.bind(this)
    this.modifySearch = this.modifySearch.bind(this)
    this.getPagination = this.getPagination.bind(this)
    this.handleExportStatusError = this.handleExportStatusError.bind(this)
    this.openExportStatusModal = this.openExportStatusModal.bind(this)
    this.closeExportStatusModal = this.closeExportStatusModal.bind(this)
    this.openErrorModal = this.openErrorModal.bind(this)
    this.closeErrorModal = this.closeErrorModal.bind(this)
  }

  componentDidMount () {
    this.setState({
      siteContext: this.props.siteContext,
      jwt: this.props.jwt,
      user: parseJwt(this.props.jwt),
      siteUrl: this.props.siteUrl
    })
  }

  // methods that modify state
  authorHarvestSearch (searchParams) {
    this.setState({
      requestLoading: true,
      searchResults: {},
      resultsMetaData: {},
      requestedParams: searchParams
    })
    HarvestApiService.getAuthorHarvestSearch(searchParams, this.props.jwt, (results) => {
      this.setState({
        searchResults: results,
        resultsMetaData: results.data.meta,
        openInputUI: false,
        requestLoading: false
      })
      return results
    }, (error) => {
      // first step in bringing the error into the UI
      if (error.response?.data.errors) {
        // error response calls modal with title and details
        // the modal confirm button sets the state of the
        // UI back to input state, to Modify the search
        this.setState({
          showErrorModal: true,
          errorStatus: error.response.status,
          modalErrorMsg: {
            title: error.response.data.errors[0].title,
            detail: error.response.data.errors[0].detail,
            confirmClick: () => { this.setState({ showErrorModal: false, openInputUI: true, requestLoading: false, searchResults: {} }) }
          }
        })
        trackFormErrorEvent(error.response.status)
      } else {
        this.setState({
          showErrorModal: true,
          modalErrorMsg: {
            title: 'Internal Server Error',
            detail: 'If this problem persists, contact your Consulting Services representative.',
            confirmClick: () => { this.setState({ showErrorModal: false, openInputUI: true, requestLoading: false, searchResults: {} }) }
          }
        })
        trackFormErrorEvent('500')
      }
      // release loading and null out numbers
      this.setState({
        searchResults: {},
        resultsMetaData: {},
        requestLoading: false
      })
    })
  }

  modifySearch (bool) {
    this.setState({
      openInputUI: bool,
      requestLoading: false,
      searchResults: {}
    })
  }

  getPagination (str) {
    this.setState({
      requestLoading: true,
      searchResults: {},
      resultsMetaData: {}
    })
    HarvestApiService.getPaginationResults(str, this.props.jwt, (results) => {
      this.setState({
        searchResults: results,
        resultsMetaData: results.data.meta,
        requestLoading: false
      })
      return results
    }, (error) => {
      console.error(error)
    })
  }

  handleExportStatusError (error) {
    if (error.response?.data.errors) {
      this.setState({
        showExportStatusModal: false,
        showErrorModal: true,
        modalErrorMsg: {
          title: error.response.data.errors[0].title,
          detail: error.response.data.errors[0].detail
        }
      })
    } else {
      this.setState({
        showExportStatusModal: false,
        showErrorModal: true,
        modalErrorMsg: {
          title: 'Internal Server Error',
          detail: 'If this problem persists, contact your Consulting Services representative.'
        }
      })
    }
  }

  openExportStatusModal () {
    this.setState({
      showExportStatusModal: true
    })
  }

  closeExportStatusModal () {
    this.setState({
      showExportStatusModal: false
    })
  }

  openErrorModal (title, detail) {
    this.setState({
      showErrorModal: true,
      modalErrorMsg: {
        title: title,
        detail: detail
      }
    })
  }

  closeErrorModal () {
    this.setState({
      showErrorModal: false,
      title: '',
      detail: ''
    })
  }

  render () {
    // the provider is renders a component that passes down state
    // and it's methods to children
    return (
      <HarvestContext.Provider value={{
        ...this.state,
        actions: {
          authorHarvestSearch: this.authorHarvestSearch,
          modifySearch: this.modifySearch,
          getPagination: this.getPagination,
          handleExportStatusError: this.handleExportStatusError,
          openExportStatusModal: this.openExportStatusModal,
          closeExportStatusModal: this.closeExportStatusModal,
          openErrorModal: this.openErrorModal,
          closeErrorModal: this.closeErrorModal
        }
      }}
      >
        {this.props.children}
      </HarvestContext.Provider>
    )
  }
}

export default HarvestProvider

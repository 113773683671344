import React, { useContext, useState, useEffect } from 'react';
import HarvestContext from '../context/HarvestContext';
import Markup from 'interweave';
import ModalExport from './ModalExport.jsx';
import HarvestApiService from '../service/HarvestApi.jsx';
import {
  trackNewPageEvent,
  trackSearchCriteria,
  trackLinkOut,
} from '../../analytics';

import '../../scss/harvest-main.scss';
import '../../scss/harvest-search-results.scss';
import { adjustFrameHeight } from '../../utils';

const OA_STATUS_GOLD = 'Gold';
const OA_STATUS_GOLD_HYBRID = 'Hybrid Gold';
const OA_STATUS_BRONZE = 'Bronze';
const OA_STATUS_GREEN_FINAL = 'Green Final';
const OA_STATUS_GREEN_ACCEPTED = 'Green Accepted';

const SearchResultsUI = () => {
  const context = useContext(HarvestContext);
  const [showAllAuthorsId, toggleShowAllAuthorsId] = useState(null);
  const [publicationsData, setPublicationsData] = useState({});
  const [showExportModal, toggleShowExportModal] = useState(false);
  const [modalExportMsg, setModalExportMsg] = useState(null);

  useEffect(() => {
    if (!context.openInputUI) {
      context.loadTracker.stopTracking();
      // track new page on mount
      trackNewPageEvent(
        `harvest:${context.requestedParams.dataSource}:search:results`,
        context.loadTracker.loadTime
      );

      // track search results event on mount
      const searchCriteria = HarvestApiService.getQueryString(
        context.requestedParams
      );
      trackSearchCriteria(
        `articles?${searchCriteria}`,
        context.requestedParams
      );

      adjustFrameHeight();
    }
  }, [context.openInputUI, context.requestedParams, context.loadTracker]);

  // adjust frame height on pagination (when results are re-hydrated)
  useEffect(() => {
    adjustFrameHeight();
  }, [context.searchResults]);

  function handleShowExportModal() {
    context.loadTracker.startTracking();
    HarvestApiService.getPublications(
      context.siteContext,
      context.jwt,
      (results) => {
        setPublicationsData(results);
        toggleShowExportModal(true);
      },
      (error) => {
        if (error.response) {
          const title = error.response.data.errors[0].title;
          const detail = error.response.data.errors[0].detail;
          context.actions.openErrorModal(title, detail);
        } else {
          const title = 'Internal Server Error';
          const detail =
            'If this problem persists, contact your Consulting Services representative.';
          context.actions.openErrorModal(title, detail);
        }
      }
    );
    setModalExportMsg({
      showExportModal: true,
      modalExportMsg: {
        title: 'Export options',
        detail: 'Select an export format by Publication',
        confirmClick: () => {
          toggleShowExportModal(false);
        },
      },
    });
  }

  // RENDERING
  function renderAuthors(authors) {
    if (typeof authors !== 'undefined') {
      return authors.slice(0, 5).map((author, index) => {
        return (
          <li key={`${index}`}>
            <span className='author-name'>
              {author.FirstName} {author.MiddleName} {author.LastName}
            </span>
            , <i>{author.Institution}</i>
          </li>
        );
      });
    }
    return false;
  }

  function renderAllAuthorsButton(authors, key) {
    if (authors.length > 5) {
      return (
        <button
          className='more-authors'
          onClick={() => {
            toggleShowAllAuthorsId(key);
          }}
        >
          more authors
          <div className='author-up' />
        </button>
      );
    }
    return null;
  }

  function renderAllAuthors(authors, key) {
    if (showAllAuthorsId === key) {
      return (
        <div className='all-authors-window'>
          <div className='all-authors-content p-2'>
            <div className='carot'>
              <div id='triangle' />
            </div>
            <header>
              {authors.length} Authors{' '}
              <span
                className='cancel-x'
                onClick={() => toggleShowAllAuthorsId(null)}
              />
            </header>
            <div className='authors-header row'>
              <div className='col-sm-4'>Name</div>
              <div className='col-sm-8'>Affiliation</div>
            </div>
            <ul className='all-authors-list'>
              {authors.slice(5).map((author, index) => {
                return (
                  <li className='all-author-name row' key={index}>
                    <div className='col-sm-4'>
                      {author.FirstName} {author.MiddleName} {author.LastName}
                    </div>
                    <div className='col-sm-8'>{author.Institution}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
    return null;
  }

  function renderOALabels(labels) {
    if (!labels) return '';
    return (
      <>
        <span className='open-access'>Open access</span>
        <ul className='list-group list-group-horizontal'>
          {labels.map((label, index) => {
            return <OALabel key={index} label={label} />;
          })}
        </ul>
      </>
    );
  }

  const OALabel = ({ label }) => {
    const [showLabelDetails, toggleShowLabelDetails] = useState(false);

    const renderOADetails = () => {
      switch (label) {
        case OA_STATUS_GOLD:
          return 'Published version with Creative Commons license, available on publisher platform. Documents are in journals which only publish open access.';
        case OA_STATUS_GOLD_HYBRID:
          return 'Published version with Creative Commons license, available on publisher platform. Documents are in journals which provide authors the choice of publishing open access.';
        case OA_STATUS_BRONZE:
          return 'Published version of record or manuscript accepted for publication, for which the publisher has chosen to provide temporary or permanent free access. Bronze status is assigned to a document if there is another (publisher-specific) license other than a Creative Commons license (e.g. Elsevier’s publisher license for Open Archive), or no license at all.';
        case OA_STATUS_GREEN_ACCEPTED:
          return 'Manuscript accepted for publication available in a repository.';
        case OA_STATUS_GREEN_FINAL:
          return 'Published version available in a repository.';
        default:
          return '';
      }
    };

    return (
      <li className='list-group-item border-0 p-0 mr-2'>
        {showLabelDetails && (
          <div
            onMouseEnter={() => toggleShowLabelDetails(true)}
            onMouseLeave={() => toggleShowLabelDetails(false)}
            className='oa-label-details'
          >
            <header>
              {label}
              <span
                className='cancel-x'
                onClick={() => toggleShowLabelDetails(false)}
              />
            </header>
            <p>{renderOADetails()}</p>
            <div className='carot'>
              <div id='triangle' />
            </div>
          </div>
        )}
        <span
          onMouseEnter={() => setTimeout(toggleShowLabelDetails(true), 1000)}
          onMouseLeave={() => toggleShowLabelDetails(false)}
          className='oa-label px-2'
        >
          {label}
        </span>
      </li>
    );
  };

  function renderHeaders() {
    // these bootstrap columns dictate the format of the data rendered
    // in renderResults
    return (
      <header>
        <ol className='result-header row'>
          <li className='col-sm-1'>&nbsp;</li>
          <li className='col-md-4 head-title'>Title</li>
          <li className='col-md-4 head-authors'>Authors</li>
          <li className='col-sm-2 head-type'>Type</li>
          <li className='col-sm-1 head-date pl-2'>Date</li>
        </ol>
      </header>
    );
  }

  function renderExport(resultsMeta) {
    return (
      <div className='export-header'>
        <button
          className='export-button d-inline-block'
          onClick={() => handleShowExportModal()}
        >
          Export
          <div className='document-icon' />
        </button>

        {showExportModal && (
          <ModalExport
            modalExportMsg={modalExportMsg}
            showModal={showExportModal}
            showExportStatus={context.actions.openExportStatusModal}
            publicationsData={publicationsData}
            requestedParams={context.requestedParams}
            totalResults={resultsMeta.total_results}
            closeModal={() => toggleShowExportModal(false)}
            jwt={context.jwt}
          />
        )}

        {/* EXPORT STATUS LINK */}
        <div className='export-status-link d-inline-block float-right'>
          <button
            className='primary-link bg-white'
            type='button'
            onClick={context.actions.openExportStatusModal}
          >
            <u>Export Status</u>
          </button>
        </div>
        <p>Export all results as an Excel file for batch upload.</p>
      </div>
    );
  }

  function renderResults(results, resultsMeta, dataSource) {
    return (
      <ol className='results-list'>
        {results.map((result, resultIndex) => {
          let title = <Markup content={result.attributes.Title} />;
          if (result.attributes.DOI) {
            let link = result.attributes.DOI;
            if (link.indexOf('http') < 0) {
              link = `https://doi.org/${result.attributes.DOI}`;
            }
            title = (
              <a
                href={link}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() =>
                  trackLinkOut(
                    result.attributes.DOI,
                    result.attributes.Title,
                    link,
                    dataSource
                  )
                }
              >
                {title}
              </a>
            );
          }
          // Results mapped out in list format
          // this return is one line item
          // the col- format is dictated by renderHeaders
          // render line numbers by index offset from results metadata page_start
          return (
            <li key={resultIndex} className='result-item row'>
              <div className='line-number col-sm-1'>
                {resultIndex + resultsMeta.page_start}
              </div>
              <div className='pub-title col-md-4'>
                <div className='title'>{title}</div>
                <div className='source-index'>
                  {renderOALabels(result.attributes.OAStatusLabels)}
                </div>
              </div>
              <ol className='authors col-md-4'>
                {renderAuthors(result.attributes.Authors)}
                <li className='all-authors-buttons'>
                  {renderAllAuthorsButton(result.attributes.Authors, result.id)}
                  {renderAllAuthors(result.attributes.Authors, result.id)}
                </li>
              </ol>
              <div className='doc-type col-sm-2'>
                {result.attributes.NativeDocumentType
                  ? result.attributes.NativeDocumentType
                  : result.attributes.DocumentType}
              </div>
              <div className='pub-date col-sm-1 text-right'>
                {result.attributes.PublicationDate}
              </div>
            </li>
          );
        })}
      </ol>
    );
  }

  function renderFooter(resultsMeta, links) {
    return (
      <ol className='results-footer row'>
        <li className='col-sm-3'>&nbsp;</li>
        <li className='col-sm-6 pagination row'>
          <div className='col-sm-1 icon-first-last'>
            <button
              className='first-page'
              onClick={() => context.actions.getPagination(links.first)}
              disabled={!links.first}
            />
          </div>
          <div className='col-sm-1 icon-prev-next'>
            <button
              className='prev-page'
              onClick={() => context.actions.getPagination(links.prev)}
              disabled={!links.prev}
            />
          </div>
          <button
            className='col-sm-2 previous'
            onClick={() => context.actions.getPagination(links.prev)}
            disabled={!links.prev}
          >
            Previous
          </button>
          <div className='col-sm-4 page-count'>
            {resultsMeta.page_start}-{resultsMeta.page_end} of{' '}
            {resultsMeta.total_results}
          </div>
          <button
            className='col-sm-2 next'
            onClick={() => context.actions.getPagination(links.next)}
            disabled={!links.next}
          >
            Next
          </button>
          <div className='col-sm-1 icon-prev-next'>
            <button
              className='next-page'
              onClick={() => context.actions.getPagination(links.next)}
              disabled={!links.next}
            />
          </div>
          <div className='col-sm-1 icon-first-last'>
            <button
              className='last-page'
              onClick={() => context.actions.getPagination(links.last)}
              disabled={!links.last}
            />
          </div>
        </li>
        <li className='col-sm-3'>
          <button
            className='to-top inline'
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
          >
            Go back to top
          </button>
        </li>
      </ol>
    );
  }

  if (context.requestLoading) {
    return (
      <div id='search-results-ui'>
        <div className='loading-indicator'>
          <div className='bubble-wrap'>
            <div className='circle'>&nbsp;</div>
            <div className='circle middle'>&nbsp;</div>
            <div className='circle last'>&nbsp;</div>
          </div>
        </div>
      </div>
    );
  }

  if (
    context.searchResults.data &&
    context.searchResults.data.data.length > 0
  ) {
    const resultsData = context.searchResults.data.data;
    const resultsMeta = context.searchResults.data.meta;
    const resultsLinks = context.searchResults.data.links;
    const dataSource = context.requestedParams.dataSource;

    return (
      <div id='search-results-ui'>
        {renderExport(resultsMeta)}
        {renderHeaders()}
        {renderResults(resultsData, resultsMeta, dataSource)}
        {renderFooter(resultsMeta, resultsLinks)}
      </div>
    );
  }
  return null;
};

export default SearchResultsUI;

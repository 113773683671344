import React, { useState, useEffect } from 'react'
import InputTextField from './InputTextField'
import InputDateField from './InputDateField'
import * as DateFns from 'date-fns'

const PubMedForm = (props) => {
  const {
    authorLast,
    authorFirst,
    authorAffiliation,
    pubDateFrom,
    pubDateTo
  } = props.searchParams

  const { checkValidForm } = props

  const [toolTipVisibility, setToolTipVisibility] = useState(false)

  const pubDateFromComplete = pubDateFrom && pubDateFrom.split('-').reduce((prev, current) => prev + current.trim().length, 0) === 8
  const pubDateToComplete = pubDateTo && pubDateTo.split('-').reduce((prev, current) => prev + current.trim().length, 0) === 8

  const parsedPubDateFrom = DateFns.parse(pubDateFrom, 'yyyy-MM-dd', new Date())
  const isValidPubDateFrom = DateFns.isMatch(pubDateFrom, 'yyyy-MM-dd')

  const parsedPubDateTo = DateFns.parse(pubDateTo, 'yyyy-MM-dd', new Date())
  const isValidPubDateTo = DateFns.isMatch(pubDateTo, 'yyyy-MM-dd')

  const invalidPubDateFrom = pubDateFromComplete ? !isValidPubDateFrom : false
  const invalidPubDateTo = pubDateToComplete ? !isValidPubDateTo : false
  const invalidPubDateRange = (parsedPubDateFrom && parsedPubDateTo) &&
      (DateFns.isAfter(parsedPubDateFrom, parsedPubDateTo) || DateFns.isBefore(parsedPubDateTo, parsedPubDateFrom))

  const formValidity = !(
    !(authorLast || authorFirst || authorAffiliation) ||
        (pubDateFrom && !pubDateFromComplete) ||
        (pubDateTo && !pubDateToComplete) ||
        (invalidPubDateFrom || invalidPubDateTo || invalidPubDateRange)
  )

  useEffect(() => {
    // validation conditions
    checkValidForm(formValidity)
  }, [formValidity, checkValidForm])

  const handleChangeInput = (target, value) => {
    props.handleChangeSearchParams({ [target]: value })
  }

  return (
    <div data-testid='form-pubmed'>
      {/* AUTHOR INPUT */}
      <div className='section-row name-switch'>
        <div className='section-title display-inline'>Author Name</div>
      </div>
      <div className='author-input row'>
        <div className='last-name col-sm-6'>
          <InputTextField
            label='Last Name'
            example='Smith'
            inputId='authorLast'
            inputValue={authorLast || ''}
            setValue={(value) => { handleChangeInput('authorLast', value) }}
          />
        </div>
        <div className='first-name col-sm-6'>
          <InputTextField
            label='First/Middle Name/Initials'
            example='John, John Lewis, J. L.'
            inputId='authorFirst'
            inputValue={authorFirst || ''}
            setValue={(value) => { handleChangeInput('authorFirst', value) }}
          />
        </div>
      </div>

      <div className='section-row'>
        <div className='section-title display-inline'>Affiliation</div>
        <div className='row'>
          <div className='pubmed-affiliation col-sm-12'>
            <InputTextField
              label='Affiliation'
              example='"University of Toronto"; "Missouri State" NOT (Southeast OR Northwest); "ABC Hospital" OR (ABC AND (Canada OR Ontario))'
              inputId='affiliation'
              inputValue={authorAffiliation || ''}
              setValue={(value) => { handleChangeInput('authorAffiliation', value) }}
            />
          </div>
        </div>
      </div>

      {/* PUBLICATION DATE INPUT */}
      <div className='section-row'>
        <div className='section-title display-inline'>
          Publication Dates
          <span
            onMouseEnter={() => { setToolTipVisibility(true) }}
            onMouseLeave={() => { setToolTipVisibility(false) }}
          >
            <i className='fas fa-exclamation-circle' style={tooltipIndicatorStyle} />
          </span>
          <div
            onMouseEnter={() => { setToolTipVisibility(true) }}
            onMouseLeave={() => { setToolTipVisibility(false) }}
            className={`pubmed-date-tooltip ${toolTipVisibility ? '' : 'no-show'}`}
          >
            <header><span className='cancel-x' onClick={() => setToolTipVisibility(false)} /></header>
            <p className='mr-3'>NOTE: Harvested works may have publication dates outside specified ranges, may appear out of order, or may differ from those found on the PubMed website due to technical limitations with the PubMed API.</p>
            <div className='carot'>
              <div id='triangle' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='pub-date col-sm-6'>
            <InputDateField
              label='From'
              example='YYYY-MM-DD'
              inputId='pubDateFrom'
              inputValue={pubDateFrom || ''}
              setValue={(value) => handleChangeInput('pubDateFrom', value)}
            />
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidPubDateFrom && 'Invalid date.'}
              {invalidPubDateRange && 'Invalid date range.'}
            </div>
          </div>
          <div className='pub-date col-sm-6'>
            <div className='input-text-field' tabIndex='-1'>
              <InputDateField
                label='To'
                example='YYYY-MM-DD'
                inputId='pubDateTo'
                inputValue={pubDateTo || ''}
                setValue={(value) => handleChangeInput('pubDateTo', value)}
              />
            </div>
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidPubDateTo && 'Invalid date.'}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

const tooltipIndicatorStyle = {
  padding: '8px',
  fontSize: '14px',
  color: '#C83727'
}

export default PubMedForm

import React from 'react'
import QueryString from 'query-string'
import HarvestProvider from '../context/HarvestProvider'
import SearchInputUI from './SearchInputUI.jsx'
import SearchStatusUI from './SearchStatusUI.jsx'
import SearchResultsUI from './SearchResultsUI.jsx'
import ModalSearchError from './ModalError.jsx'
import ModalExportStatus from './ModalExportStatus.jsx'
import { generateInitialPageData, LoadTracker } from '../../analytics'
import '../../scss/harvest-main.scss'

const HarvestUI = (props) => {
  const loadTracker = new LoadTracker()
  loadTracker.startTracking()
  const params = QueryString.parse(props.location.search)
  const urlParam = params.context
  const siteContext = props.match.params.context
  const jwt = props.match.params.jwt
  const siteUrl = document.referrer || '*'

  // track initial page load
  window.pageData = generateInitialPageData()
  loadTracker.stopTracking()
  window.pageData.page.loadTime = loadTracker.loadTime
  window.pageDataTracker.trackPageLoad()

  const scrollToTop = () => {
    window.parent.postMessage('scroll-up', siteUrl)
  }

  // HarvestUI is a shell for the Search, Results and other supplemental components;
  // it renders the HarvestProvider where state is initialized and passed down to children
  // Errors for API service can be dealt off to ModalError
  return (
    <HarvestProvider siteContext={siteContext} jwt={jwt} siteUrl={siteUrl}>
      <div id='main-page' className='normal-sizing' tabIndex='-1'>
        <div className='url-params'>{urlParam}</div>
        <ModalSearchError />
        <ModalExportStatus />
        <div className='harvestor-body'>
          <SearchStatusUI />
          <SearchInputUI scrollToTop={scrollToTop} />
          <SearchResultsUI />
        </div>
      </div>
    </HarvestProvider>
  )
}

export default HarvestUI

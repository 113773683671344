import CheckBox from 'rc-checkbox'
import React, { useState, useEffect, useContext } from 'react'
import HarvestContext from '../context/HarvestContext'
import { trackNewPageEvent, trackNavigationClick } from '../../analytics'

const ModalContentType = ({ showModal, contentTypes, handleContentTypeSelected, closeModal }) => {
  const context = useContext(HarvestContext)
  const [selected, setSelected] = useState([])
  const [selectionMade, toggleSelectionMade] = useState(false)

  useEffect(() => {
    if (showModal) {
      context.loadTracker.stopTracking()
      trackNewPageEvent('harvest:search:pure:facet:content-type', context.loadTracker.loadTime)
    }
  }, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // clear selected when form is cleared
    if (contentTypes && contentTypes.length === 0) setSelected([])
  }, [contentTypes])

  const handleSelectContentTypeCategory = (event, category) => {
    const { target } = event
    setSelected(target.checked ? [...selected, ...mapSelectedContentTypeCategory(category)] : deSelectContentTypeCategory(category))
    toggleSelectionMade(true)
  }

  const mapSelectedContentTypeCategory = (category) => {
    const selectedCategory = [mapSelectedContentType(category)]
    category.children.forEach(c => {
      const isSelected = selected.find(s => s.uri === c.typeUri)
      if (!isSelected) selectedCategory.push(mapSelectedContentType(c, category.description))
    })
    return selectedCategory
  }

  const deSelectContentTypeCategory = (category) => {
    let deselectedCategory = selected.filter(s => s.uri !== category.typeUri)
    category.children.forEach(c => {
      deselectedCategory = deselectedCategory.filter(s => s.uri !== c.typeUri)
    })
    return deselectedCategory
  }

  const handleSelectContentType = (event, type) => {
    const { target } = event
    setSelected(target.checked ? [...selected, type] : selected.filter(f => f.uri !== type.uri))
    toggleSelectionMade(true)
  }

  const mapSelectedContentType = (type, category) => {
    return {
      name: type.description,
      uri: type.typeUri,
      category: category
    }
  }

  const handleOkClick = () => {
    trackNavigationClick('modal-footer', 'ok-button')
    handleContentTypeSelected(selected)
    toggleSelectionMade(false)
  }

  const handleCancelClick = () => {
    trackNavigationClick('modal-footer', 'cancel-button')
    toggleSelectionMade(false)
    closeModal()
  }

  const renderContentTypeList = (types, category) => {
    return (
      types.map(t => {
        return (
          <li key={t.typeUri} className='list-group-item border-0 p-0'>
            <CheckBox
              className='checkbox'
              name={t.typeUri}
              checked={selected.find(x => x.uri === t.typeUri) !== undefined}
              onChange={(e) => handleSelectContentType(e, mapSelectedContentType(t, category))}
            />
            <label className='mx-3'>{t.description}</label>
          </li>
        )
      })
    )
  }

  const renderContentTypes = (data) => {
    return (
      <div className='row px-3 pb-5'>
        {
          data.map(contentType => {
            const { typeUri, description, children } = contentType.attributes
            return (
              <div key={contentType.id}>
                <ul className='list-group mb-3'>
                  <li key={typeUri} className='list-group-item border-0 px-0'>
                    <CheckBox
                      className='checkbox'
                      name={typeUri}
                      checked={selected.find(x => x.uri === typeUri) !== undefined}
                      onChange={(e) => handleSelectContentTypeCategory(e, contentType.attributes)}
                    />
                    <label className='mx-3'>{description}</label>
                    <hr />
                  </li>
                  {renderContentTypeList(children, description)}
                </ul>
              </div>
            )
          })
        }
      </div>
    )
  }

  if (showModal) {
    return (
      <div className='modal' id='modal-content-types' style={{ display: 'block' }}>
        <div className='modal-dialog'>
          <div className='dialog-box modal-content'>
            <div className='dialog-text my-2 modal-header border-bottom-0'>
              <div className='content-types-title pl-4'>
                <div className='cancel-x' onClick={handleCancelClick} />
                <CheckBox
                  className='checkbox'
                  checked
                />
                <label className='ml-2'>Records of Content Type</label>
              </div>
              <hr />
              <div className='content-types-detail pl-4'>
                Make your selections
              </div>
            </div>
            <div className='dialog-table px-3 pb-5'>
              {renderContentTypes(contentTypes)}
            </div>
            <div className='dialog-footer border-top modal-footer'>
              <button className='cancel-button mr-3 my-2' onClick={handleCancelClick}>
                Cancel
              </button>
              <button className={`ok-button ${!selectionMade ? 'disabled' : ''}`} onClick={handleOkClick} disabled={!selectionMade}>
                Ok
                <div className='nav-right rotate-right' />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

export default ModalContentType

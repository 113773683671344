import React, { useContext } from 'react'
import HarvestContext from '../context/HarvestContext'
import '../../scss/modal.scss'

const ModalError = () => {
  const context = useContext(HarvestContext)

  if (!context.showErrorModal) {
    return ''
  }
  return (
    <div id='modal-error'>
      <div className='dialog-box'>
        <hr />
        <div className='row'>
          <div className='col'>
            <div className='cancel-x' onClick={() => context.actions.closeErrorModal()} />
          </div>
        </div>
        <div className='dialog-img inline'>
          <div className='tool-kit' />
        </div>
        <div className='dialog-text inline'>
          <div className='error-title'>
            {context.modalErrorMsg.title}
          </div>
          <div className='error-detail'>
            {context.modalErrorMsg.detail}
          </div>
        </div>
        <div className='bottom-row'>
          <button className='confirm' onClick={() => context.actions.closeErrorModal()}>
            Back to search options
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalError

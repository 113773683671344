import React, { useEffect } from 'react'
import ModalAffiliations from '../ModalAffiliations'
import InputTextField from './InputTextField'

const ScopusForm = (props) => {
  const {
    authorFirst,
    authorLast,
    authorId,
    orcidId,
    authorAffiliation,
    selectedAffiliations,
    pubYearFrom,
    pubYearTo
  } = props.searchParams

  const { checkValidForm } = props

  const authorIncomplete = (authorLast && !(authorFirst || authorAffiliation)) || (authorFirst && !authorLast)
  const pubYearFromComplete = (pubYearFrom && pubYearFrom.length === 4)
  const pubYearToComplete = (pubYearTo && pubYearTo.length === 4)
  const invalidPubYearFrom = pubYearFromComplete && isNaN(parseInt(pubYearFrom))
  const invalidPubYearTo = pubYearToComplete && isNaN(parseInt(pubYearTo))
  const invalidPubYearRange = (pubYearFromComplete && pubYearToComplete) && parseInt(pubYearFrom) > parseInt(pubYearTo)

  const authorTooltip = authorIncomplete || !authorLast
  const nameDisabled = (authorId || orcidId ? 'disabled' : '')
  const authorIdDisabled = (authorLast || authorFirst || orcidId || authorAffiliation) ? 'disabled' : ''
  const orcidIdDisabled = (authorLast || authorFirst || authorId || authorAffiliation) ? 'disabled' : ''
  const affiliationDisabled = (authorId || orcidId)

  const formValidity = !(
    !(authorLast || authorFirst || authorId || orcidId || authorAffiliation) ||
    authorIncomplete ||
    (pubYearFrom && !pubYearFromComplete) ||
    (pubYearTo && !pubYearToComplete) ||
    (invalidPubYearFrom || invalidPubYearTo || invalidPubYearRange)
  )

  useEffect(() => {
    // validation conditions
    checkValidForm(formValidity) // eslint-disable-next-line
  },[formValidity])

  const handleChangeInput = (target, value) => {
    props.handleChangeSearchParams({ [target]: value })
  }

  const handleSelectAffiliation = (affiliation) => {
    let selected = []

    if (!selectedAffiliations) {
      selected = [affiliation]
    } else if (!selectedAffiliations.includes(affiliation)) {
      // affiliation isn't selected yet, add it to selected
      selected = [...selectedAffiliations, affiliation]
    } else {
      // affiliation is deselected
      selected = selectedAffiliations.filter(x => x !== affiliation)
    }

    props.handleChangeSearchParams({ selectedAffiliations: selected })
  }

  return (
    <div data-testid='form-scopus'>
      <ModalAffiliations
        dataSource='scopus'
        showModal={props.showAffiliationModal}
        affiliationSearchParam={authorAffiliation}
        affiliationsData={props.searchParams.affiliationsList}
        selectedAffiliations={selectedAffiliations}
        closeModal={() => props.closeAffiliationModal()}
        handleSelectAffiliation={handleSelectAffiliation}
        handleContinueSearch={props.handleContinueSearch}
      />

      {/* AUTHOR INPUT */}
      <div className='section-row name-switch'>
        <div className='section-title display-inline'>Author Name</div>
        <div className='search-input helptext-small' hidden={`${authorTooltip ? '' : 'no-show'}`}>
          Requires 'Last Name' together with 'First Name' or 'Affiliation’
        </div>
      </div>

      <div className='author-input row'>
        <div className='last-name col-sm-6'>
          <InputTextField
            label='Last Name'
            example='Smith'
            inputId='authorLast'
            disabled={nameDisabled}
            inputValue={authorLast || ''}
            setValue={(value) => { handleChangeInput('authorLast', value) }}
          />
        </div>
        <div className='first-name col-sm-6'>
          <InputTextField
            label='First/Middle Name/Initials'
            example='John, John Lewis, J. L.'
            inputId='authorFirst'
            disabled={nameDisabled}
            inputValue={authorFirst || ''}
            setValue={(value) => { handleChangeInput('authorFirst', value) }}
          />
        </div>
      </div>
      <div className='section-row'>
        <div className='section-title display-inline'>Author ID</div>
        <div className='row'>
          <div className='scopus-id col-sm-6'>
            <InputTextField
              label='Scopus ID'
              example='91234567890'
              inputId='authorId'
              disabled={authorIdDisabled}
              inputValue={authorId || ''}
              setValue={(value) => { handleChangeInput('authorId', value) }}
            />
          </div>
          <div className='orcid-id col-sm-6'>
            <InputTextField
              label='ORCID ID'
              example='0123-2345-4567-7890'
              inputId='orcidId'
              disabled={orcidIdDisabled}
              inputValue={orcidId || ''}
              setValue={(value) => { handleChangeInput('orcidId', value) }}
            />
          </div>
        </div>
      </div>
      <div disabled={affiliationDisabled} className='section-row'>
        <div className='section-title display-inline'>Affiliation</div>
        <div className='row'>
          <div className='author-affiliation col-sm-12'>
            <InputTextField
              label='Author Affiliation'
              example='University of Toronto'
              inputId='authorAffiliation'
              inputValue={authorAffiliation || ''}
              disabled={affiliationDisabled}
              setValue={(value) => { handleChangeInput('authorAffiliation', value) }}
              autoComplete='off'
            />
          </div>
          <div className={`search-input col-sm-6 error ${props.affiliationToolTip ? '' : 'no-show'}`}>
            Click 'Search' to review your current selection of Scopus affiliations
          </div>
        </div>
      </div>
      <div className='section-row'>
        <div className='section-title display-inline'>Publication Dates</div>
        <div className='row'>
          <div className='pub-date col-sm-6'>
            <InputTextField
              label='From'
              example='2001'
              inputId='pubYearFrom'
              inputValue={pubYearFrom || ''}
              setValue={(value) => { handleChangeInput('pubYearFrom', value) }}
            />
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidPubYearFrom && 'Invalid year.'}
              {invalidPubYearRange && 'Invalid date range.'}
            </div>
          </div>
          <div className='pub-date col-sm-6'>
            <InputTextField
              label='To'
              example='2020'
              inputId='pubYearTo'
              inputValue={pubYearTo || ''}
              setValue={(value) => { handleChangeInput('pubYearTo', value) }}
            />
            <div className='search-input col-sm-6 helptext-small error'>
              {invalidPubYearTo && 'Invalid year.'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScopusForm
